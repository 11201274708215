import List from './list';
export default function Pesquisadores({ data }) {
    const displayData = data.map((element, index) => (
        <List element={element} key={index + element.descricao} />
    ))
    return (
        <>
            <div className="sobre-nos-element-wrapper">
                {displayData}
            </div>
        </>
    );
};