import ReactFlip from "react-card-flip";
import { useState } from "react";
export default function Pesquisadores({ element }) {
  const [flipped, setFlipped] = useState(false);
  return (
    <>
      <div
        cursor="pointer"
        onClick={() => setFlipped(!flipped)}
        className={"card-filp-sobre-nos"}
      >
        <ReactFlip isFlipped={flipped} flipDirection="horizontal">
          <div
            className="sobre-nos-element-image"
            style={{ backgroundImage: "url(" + element.photo + ")" }}
          />
          <div className="sobreNos-overflow-text">
            <p className="sobreNos-text-wrapper" style={{ color: "#ffffff" }}>
              {element.descricao}
            </p>
          </div>
        </ReactFlip>
      </div>
    </>
  );
}
