import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
export default function Filter({ filter, setFilter, forceUpdate }) {
  return (
    <>
      <FormControlLabel
        control={
          <Checkbox
            color="#ffffff"
            checked={filter.empreendedor.Público}
            onChange={() => {
              var data = filter;
              data.empreendedor.Público = !data.empreendedor.Público;
              setFilter(data);
              forceUpdate();
            }}
            name="Norte"
          />
        }
        label={<p style={{ fontFamily: "quantico" }}>Público</p>}
        />
      <FormControlLabel
        control={
          <Checkbox
            color="#ffffff"
            checked={filter.empreendedor.Privado}
            onChange={() => {
              var data = filter;
              data.empreendedor.Privado = !data.empreendedor.Privado;
              setFilter(data);
              forceUpdate();
            }}
            name="Norte"
          />
        }
        label={<p style={{ fontFamily: "quantico" }}>Privado</p>}
        />
      <FormControlLabel
        control={
          <Checkbox
            color="#ffffff"
            checked={filter.empreendedor.Ambos}
            onChange={() => {
              var data = filter;
              data.empreendedor.Ambos = !data.empreendedor.Ambos;
              setFilter(data);
              forceUpdate();
            }}
            name="Norte"
          />
        }
        label={<p style={{ fontFamily: "quantico" }}>Ambos</p>}
        />
    </>
  );
}
