import { useState, useEffect } from "react";
import image from "./../../assets/images/background/atlas-image.png";
import { NavLink } from "react-router-dom";
import Helmet from "react-helmet";
import institucional from "./../../assets/images/institucional/vetor institucionais.svg";
import marked from "marked";
import cnpq from "./../../assets/images/institucional/pb_cnpq.jpeg";
import firebase from "./../../services/initFirebase";
export default function Home() {
  const [cell, setCell] = useState(1);
  const [text, setText] = useState("");
  const secondCell = () => {
    if (cell === 2) {
      return (
        <>
          <div className="small-text-wrapper">
            <div dangerouslySetInnerHTML={renderText()} />
          </div>
        </>
      );
    } else {
      return null;
    }
  };

  const firstCell = () => {
    if (cell === 1) {
      return (
        <>
          <br />
          <NavLink to={"/oAtlas"} className="home-page-buttons">
            O ATLAS
          </NavLink>
          <NavLink to={"/dispositivo"} className="home-page-buttons">
            DISPOSITIVO-ATLAS
          </NavLink>
          <NavLink to={"/mural"} className="home-page-buttons">
            MURAL DE NARRATIVAS
          </NavLink>
          <NavLink to={"/sobre"} className="home-page-buttons">
            + SOBRE NÓS
          </NavLink>
        </>
      );
    } else {
      return null;
    }
  };

  const cellIndicators = (
    <>
      <div className="cell-indicators-wrapper">
        <div
          onClick={() => setCell(1)}
          className={cell === 1 ? "cell-indicator-selected" : "cell-indicator"}
        ></div>
        <div
          onClick={() => setCell(2)}
          className={cell === 2 ? "cell-indicator-selected" : "cell-indicator"}
        ></div>
      </div>
    </>
  );

  const control = 0;
  const getText = () => {
    firebase
      .firestore()
      .collection("content")
      .doc("content")
      .onSnapshot((doc) => {
        setText(doc.data().home);
      });
  };

  useEffect(() => {
    getText();
  }, [control]);

  const renderText = () => {
    const __html = marked(text, { sanitize: true });
    return { __html };
  };
  return (
    <>
      <div className="home-page-body">
        <div className="left-side-page-wrapper ">
          <div className="home-page-title-wrapper">
            <h1 className="home-page-title">Cidades Novas</h1>
          </div>
          {window.innerWidth < 600 ? cellIndicators : null}
          {window.innerWidth < 600 ? (
            secondCell()
          ) : (
            <>
              <br />
              <div dangerouslySetInnerHTML={renderText()} />
            </>
          )}

          {window.innerWidth < 600 ? (
            firstCell()
          ) : (
            <>
              <br />
              <NavLink to={"/oAtlas"} className="home-page-buttons">
                O ATLAS
              </NavLink>
              <NavLink to={"/dispositivo"} className="home-page-buttons">
                DISPOSITIVO-ATLAS
              </NavLink>
              <NavLink to={"/mural"} className="home-page-buttons">
                MURAL DE NARRATIVAS
              </NavLink>
              <NavLink to={"/sobre"} className="home-page-buttons">
               + SOBRE NÓS
              </NavLink>
            
            </>
          )}
          <div className="institutional-images-wrapper">
            <img
              src={institucional}
              alt=""
              className="big-svg-institutional-images"
            />
            <img src={cnpq} alt="" className="institutional-image-cnpq" />
          </div>
        </div>
        <div className="right-side-page-wrapper">
          <img alt="" style={{ width: "100%", height: "auto" }} src={image} />
        </div>
        <Helmet>
          <title>Cidades novas</title>
          <meta name="description" content="O atlas de novas cidades" />
        </Helmet>
      </div>
    </>
  );
}
