import firebase from './../../../services/initFirebase';
import { useState } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}
export default function NewContent() {
    const [name, setName] = useState("");
    const [photo, setPhoto] = useState("");
    const [photoObject, setPhotoObject] = useState({});
    const [type, setType] = useState("Pesquisa");
    const [photoChanged, setPhotoChanged] = useState(false);
    const [severity, setSeverity] = useState("success");
    const [message, setMessage] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const handleClick = () => {
        setOpen(true);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };
    const update = async () => {
        setIsLoading(true);
        firebase.firestore().collection(type).add({
            descricao: name,
            photo: "",
            id: ""
        }).then((doc) => {
            if (photoChanged === true) {
                const storageRef = firebase.storage().ref(`${type}`)
                const fileRef = storageRef.child(photoObject.name)
                var uploadTask = fileRef.put(photoObject);
                uploadTask.on('state_changed', function (snapshot) {
                    // Observe state change events such as progress, pause, and resume
                    // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                    var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                }, function (error) {
                    // Handle unsuccessful uploads
                }, function () {
                    // Handle successful uploads on complete
                    // For instance, get the download URL: https://firebasestorage.googleapis.com/...
                    uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
                        firebase.firestore().collection(type).doc(doc.id).update({ photo: downloadURL, id: doc.id }).then(() => {
                            //handleClick({ vertical: 'top', horizontal: 'right' })
                            setIsLoading(false);
                            document.getElementById(`edit-cities-file-input-${name}`).value = null
                            setMessage("Cadastro efetuado com sucesso!");
                            setSeverity("success");
                            handleClick();
                            setName("");
                            setType("Pesquisa");
                            setPhoto("");
                            setPhotoObject({});
                            setPhotoChanged(false)
                        })
                    });
                });
            } else {
                setIsLoading(false);
                document.getElementById(`edit-cities-file-input-${name}`).value = null
                setMessage("Cadastro efetuado com sucesso!");
                setSeverity("success");
                setName("");
                setType("Pesquisa");
                setPhoto("");
                setPhotoObject({});
                setPhotoChanged(false)
                handleClick();
            }
        }).catch(() => {
            setIsLoading(false);
            setMessage("Ocorreu um erro ao efetuar o cadastro");
            setSeverity("error");
            handleClick();
        })
    }
    return (
        <>
        <h3  style={{marginLeft:"15px", marginTop:"20px"}}>Cadastro de conteúdo + Sobre Nós</h3>
            <div className="row-city-item-input">
                <div>
                    <span>Tipo:</span>
                    <select value={type} onChange={(e) => setType(e.target.value)} className="avaliacao-narrativas-input" >
                        <option value="Pesquisas">Pesquisa</option>
                        <option value="Pesquisadores">Pesquisador</option>
                        <option value="Publicações">Publicação</option>
                    </select>

                </div>
            </div>
            <div className="row-city-item-input">
                <div>
                    <span>Descrição:</span>
                    <textarea value={name} onChange={(e) => setName(e.target.value)} className="narrativa-text-area" />

                </div>
            </div>
            <img className="city-item-image" src={photo} />
            <div className="row-city-item-input">
                <div>
                    <span>Foto:</span>
                    <input name={"city-photo"} style={{ marginBottom: "15px" }} type="file" id={`edit-cities-file-input-${name}`} onChange={(e) => {
                        if (e.target.files[0]) {
                            setPhotoChanged(true);
                            const blobUrl = URL.createObjectURL(e.target.files[0]) // blob is the Blob object
                            setPhoto(blobUrl)
                            setPhotoObject(e.target.files[0])
                        } else {
                            setPhotoChanged(false)
                            setPhoto("")
                        }

                    }} accept="image/*"
                    />
                </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row", marginBottom: "20px", paddingLeft: "15px" }}>
                <button onClick={() => update()} className="avaliacao-narrativas-button" >Cadastrar</button>
                {
                    isLoading === true ? <CircularProgress style={{ height: "30px", width: "30px", paddingLeft: "8px" }} /> : null
                }
            </div>

            <div className="bottom-city-item-divider-new-content" />
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity={severity}>
                    {message}
                </Alert>
            </Snackbar>
        </>
    );
};