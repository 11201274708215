import firebase from './../../../../services/initFirebase';
import { useState } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}
export default function CityItem({ data }) {
    const [name, setName] = useState(data.descricao);
    const [photo, setPhoto] = useState(data.photo);
    const [photoObject, setPhotoObject] = useState({});
    const [photoChanged, setPhotoChanged] = useState(false);
    const [severity, setSeverity] = useState("success");
    const [message, setMessage] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const handleClick = () => {
        setOpen(true);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };
    const update = async () => {
        setIsLoading(true);
        firebase.firestore().collection("Pesquisadores").doc(data.id).update({
            descricao: name,
        }).then(() => {
            if (photoChanged === true) {
                const storageRef = firebase.storage().ref("Pesquisadores/")
                const fileRef = storageRef.child(photoObject.name)
                var uploadTask = fileRef.put(photoObject);
                uploadTask.on('state_changed', function (snapshot) {
                    // Observe state change events such as progress, pause, and resume
                    // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                    var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                }, function (error) {
                    // Handle unsuccessful uploads
                }, function () {
                    // Handle successful uploads on complete
                    // For instance, get the download URL: https://firebasestorage.googleapis.com/...
                    uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
                        firebase.firestore().collection('Pesquisadores').doc(data.id).update({ photo: downloadURL }).then(() => {
                            //handleClick({ vertical: 'top', horizontal: 'right' })
                            setIsLoading(false);
                            setMessage("Cidade atualizada com sucesso!");
                            setSeverity("success");
                            handleClick();
                        })
                    });
                });
            } else {
                setIsLoading(false);
                setMessage("Cidade atualizada com sucesso!");
                setSeverity("success");
                handleClick();
            }
        }).catch((err) => {
            setIsLoading(false);
            setMessage("Ocorreu um erro ao ataulizar a cidade!");
            setSeverity("error");
            handleClick();
        })
    }

    const deleteCity = async () => {
        setIsLoading(true);
        firebase.firestore().collection("Pesquisadores").doc(data.id).delete().then(() => {
            setIsLoading(false);
            setMessage("Cidade excluída com sucesso!");
            setSeverity("success");
            handleClick();
        }).catch(() => {
            setIsLoading(false);
            setMessage("Ocorreu um erro ao excluir a cidade!");
            setSeverity("error");
            handleClick();
        })
    }
    return (
        <div className="city-item-divider">
            <div className="row-city-item-input">
                <div>
                    <span>Descrição:</span>
                    <textarea value={name} onChange={(e) => setName(e.target.value)} className="narrativa-text-area" />
                </div>
            </div>
            <img className="city-item-image" src={photo} />
            <div className="row-city-item-input">
                <div>
                    <span>Foto:</span>
                    <input name={"city-photo"} style={{ marginBottom: "15px" }} type="file" id={`edit-cities-file-input-${data.name}`} onChange={(e) => {
                        if (e.target.files[0]) {
                            setPhotoChanged(true);
                            const blobUrl = URL.createObjectURL(e.target.files[0]) // blob is the Blob object
                            setPhoto(blobUrl)
                            setPhotoObject(e.target.files[0])
                        } else {
                            setPhotoChanged(false)
                            setPhoto(data.photo)
                        }

                    }} accept="image/*"
                    />
                </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row", marginBottom: "20px", paddingLeft: "15px" }}>
                <button onClick={() => update()} className="avaliacao-narrativas-button" >Atualizar</button>
                {
                    isLoading === true ? <CircularProgress style={{ height: "30px", width: "30px", paddingLeft: "8px" }} /> : null
                }
                {
                    isLoading === true ? null : <IconButton onClick={() => deleteCity()} color="secondary"  ><DeleteIcon /></IconButton>
                }
            </div>

            <div className="bottom-city-item-divider" />
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity={severity}>
                    {message}
                </Alert>
            </Snackbar>
        </div>
    );
};