import React from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
//Páginas
import Home from "./views/home";
import Oatals from "./views/oAtlas";
import Dispositivo from "./views/dispositivo";
import Avaliacao from "./views/avaliacao";
import SobreNos from "./views/sobreNos";
import Narrativas from "./views/narrativas";
import Admin from "./views/admin";
import SobreNosAdmin from "./views/sobreNosAdmin";
import AdminPage from "./views/admin/page";
import Content from "./views/admin/content/index";
export default function Routes() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/oAtlas" component={Oatals} />
        <Route path="/dispositivo" component={Dispositivo} />
        <Route path="/mural" component={Narrativas} />
        <Route path="/admin/avaliacao" component={Avaliacao} />
        <Route path="/sobre" component={SobreNos} />
        <Route path="/admin" exact component={AdminPage} />
        <Route path="/admin/cidades" component={Admin} />
        <Route path="/admin/sobreNos" component={SobreNosAdmin} />
        <Route path="/admin/conteudo" component={Content} />
        <Redirect from="*" to={"/"} />
      </Switch>
    </BrowserRouter>
  );
}
