import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
export default function Filter({ filter, setFilter, forceUpdate }) {
  return (
    <div style={{ columnCount: 4 }}>
      <FormControlLabel
        control={
          <Checkbox
            color="#ffffff"
            checked={filter.projeto.Modernista}
            onChange={() => {
              var data = filter;
              data.projeto.Modernista = !data.projeto.Modernista;
              setFilter(data);
              forceUpdate();
            }}
            name="Norte"
          />
        }
        label={
          <p style={{ fontFamily: "quantico", whiteSpace: "nowrap" }}>
            Modernista
          </p>
        }
      />
      <FormControlLabel
        control={
          <Checkbox
            color="#ffffff"
            checked={filter.projeto["Cidade-Jardim"]}
            onChange={() => {
              var data = filter;
              data.projeto["Cidade-Jardim"] = !data.projeto["Cidade-Jardim"];
              setFilter(data);
              forceUpdate();
            }}
            name="Norte"
          />
        }
        label={
          <p style={{ fontFamily: "quantico", whiteSpace: "nowrap" }}>
            Cidade-Jardim
          </p>
        }
      />
      <FormControlLabel
        control={
          <Checkbox
            color="#ffffff"
            checked={filter.projeto["Cidade Monumental"]}
            onChange={() => {
              var data = filter;
              data.projeto["Cidade Monumental"] =
                !data.projeto["Cidade Monumental"];
              setFilter(data);
              forceUpdate();
            }}
            name="Norte"
          />
        }
        label={
          <p style={{ fontFamily: "quantico", whiteSpace: "nowrap" }}>
            Cidade Monumental
          </p>
        }
      />
      <FormControlLabel
        control={
          <Checkbox
            color="#ffffff"
            checked={filter.projeto["City Beautiful"]}
            onChange={() => {
              var data = filter;
              data.projeto["City Beautiful"] = !data.projeto["City Beautiful"];
              setFilter(data);
              forceUpdate();
            }}
            name="Norte"
          />
        }
        label={
          <p style={{ fontFamily: "quantico", whiteSpace: "nowrap" }}>
            City Beautiful
          </p>
        }
      />
      <FormControlLabel
        control={
          <Checkbox
            color="#ffffff"
            checked={filter.projeto["Cidade Artística"]}
            onChange={() => {
              var data = filter;
              data.projeto["Cidade Artística"] =
                !data.projeto["Cidade Artística"];
              setFilter(data);
              forceUpdate();
            }}
            name="Norte"
          />
        }
        label={
          <p style={{ fontFamily: "quantico", whiteSpace: "nowrap" }}>
            Cidade Artística
          </p>
        }
      />

      <FormControlLabel
        control={
          <Checkbox
            color="#ffffff"
            checked={filter.projeto["Cidade Compacta"]}
            onChange={() => {
              var data = filter;
              data.projeto["Cidade Compacta"] =
                !data.projeto["Cidade Compacta"];
              setFilter(data);
              forceUpdate();
            }}
            name="Norte"
          />
        }
        label={
          <p style={{ fontFamily: "quantico", whiteSpace: "nowrap" }}>
            Cidade Compacta
          </p>
        }
      />
      <FormControlLabel
        control={
          <Checkbox
            color="#ffffff"
            checked={filter.projeto["Cidade Linear"]}
            onChange={() => {
              var data = filter;
              data.projeto["Cidade Linear"] = !data.projeto["Cidade Linear"];
              setFilter(data);
              forceUpdate();
            }}
            name="Norte"
          />
        }
        label={
          <p style={{ fontFamily: "quantico", whiteSpace: "nowrap" }}>
            Cidade Linear
          </p>
        }
      />
      <FormControlLabel
        control={
          <Checkbox
            color="#ffffff"
            checked={filter.projeto["Cidade Radiocêntrica"]}
            onChange={() => {
              var data = filter;
              data.projeto["Cidade Radiocêntrica"] =
                !data.projeto["Cidade Radiocêntrica"];
              setFilter(data);
              forceUpdate();
            }}
            name="Cidade Radiocêntrica"
          />
        }
        label={
          <p style={{ fontFamily: "quantico", whiteSpace: "nowrap" }}>
            Cidade Radiocêntrica
          </p>
        }
      />
      <FormControlLabel
        control={
          <Checkbox
            color="#ffffff"
            checked={filter.projeto["Malha ortogonal"]}
            onChange={() => {
              var data = filter;
              data.projeto["Malha ortogonal"] = !data.projeto["Malha ortogonal"];
              setFilter(data);
              forceUpdate();
            }}
            name="Malha xadrez"
          />
        }
        label={
          <p style={{ fontFamily: "quantico", whiteSpace: "nowrap" }}>
            Malha ortogonal
          </p>
        }
      />
      <FormControlLabel
        control={
          <Checkbox
            color="#ffffff"
            checked={filter.projeto.Outro}
            onChange={() => {
              var data = filter;
              data.projeto.Outro = !data.projeto.Outro;
              setFilter(data);
              forceUpdate();
            }}
            name="Norte"
          />
        }
        label={
          <p style={{ fontFamily: "quantico", whiteSpace: "nowrap" }}>Outro</p>
        }
      />
    </div>
  );
}
