import "./styles.css"
import { useState } from 'react'
import { BsList } from 'react-icons/bs';
import { NavLink } from 'react-router-dom';
export default function Navbar({ name }) {
    const [open, setOpen] = useState(false)
    return (
        <>
            <div className="navbar-body">
                <div className="navbar-page-name-wrapper">
                    <h1 className="page-name">{name}</h1>
                </div>
                <div className="big-nabvar-buttons-wrapper">
                    <NavLink style={{marginTop:"7px"}} className="navButton" to="/" >Menu Principal</NavLink>
                    <NavLink style={{marginTop:"7px"}} className="navButton" to="/oAtlas">O Atlas</NavLink>
                    <NavLink style={{marginTop:"7px"}} className="navButton" to="/dispositivo">Dispositivo - Atlas</NavLink>
                    <NavLink style={{marginTop:"7px"}} className="navButton" to="/mural">Mural de Narrativas</NavLink>
                    <NavLink style={{marginTop:"7px"}} className="navButton" to="/sobre">+ Sobre Nós</NavLink>
                </div>
            </div>
            <div className="small-navbar">
                <div>
                    <BsList onClick={() => { setOpen(!open) }} style={{ paddingRight: "10px" }} color="#57397e" size={35} />
                </div>
                {
                    open === true ?
                        <div className="small-nav-buttons-wrapper">
                            <NavLink className="navButton" to="/" >Menu Principal</NavLink>
                            <NavLink className="navButton" to="/oAtlas">O Atlas</NavLink>
                            <NavLink className="navButton" to="/dispositivo">Dispositivo - Atlas</NavLink>
                            <NavLink className="navButton" to="/mural">Mural de Narrativas</NavLink>
                            <NavLink className="navButton" to="/sobre">+ Sobre Nós</NavLink>
                        </div> : null
                }
                <div className="navbar-page-name-wrapper">
                    <h1 className="page-name">{name}</h1>
                </div>



            </div>
        </>
    );
};