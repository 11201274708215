import Navbar from './../../components/navbar';
import { useState, useEffect } from 'react';
import Pesquisadores from './pesquisadores';
import Pesquisas from './pesquisas';
import Publicacoes from './publicacoes';
import Button from './../../components/button';
import firebase from './../../services/initFirebase';
import Helmet from 'react-helmet';
export default function SobreNos() {
    const [selected, setSelected] = useState("Pesquisadores");
    const [argument, setArgument] = useState("");
    const [allPesquisadores, setAllPesquisadores] = useState([]);
    const [allPesquisas, setAllPesquisas] = useState([]);
    const [allPublicacoes, setAllPublicacoes] = useState([]);
    const [pesquisadores, setPesquisadores] = useState(allPesquisadores);
    const [pesquisas, setPesquisas] = useState(allPesquisas);
    const [publicacoes, setPublicacoes] = useState(allPublicacoes);

    const getData = () => {
        firebase.firestore().collection("Pesquisadores").get().then(snapsot => {
            console.log("cidades", snapsot.query);

            snapsot.forEach((doc) => {
                setAllPesquisadores(allPesquisadores => allPesquisadores.concat(doc.data()));
                setPesquisadores(pesquisadores => pesquisadores.concat(doc.data()));

            })
        }).catch((err) =>{
        })
        firebase.firestore().collection("Pesquisa").get().then(snapsot => {
            snapsot.forEach((doc) => {
                setAllPesquisas(allPesquisas => allPesquisas.concat(doc.data()));
                setPesquisas(pesquisas => pesquisas.concat(doc.data()));
            })
        }).catch((err) =>{
        })
        firebase.firestore().collection("Publicações").get().then(snapsot => {
            snapsot.forEach((doc) => {
                setAllPublicacoes(allPublicacoes => allPublicacoes.concat(doc.data()));
                setPublicacoes(publicacoes => publicacoes.concat(doc.data()));
            })
        }).catch((err) =>{
        })
    }
    const control = 0;
    useEffect(() => {
        getData();
    }, [control])
    const search = (value) => {
        if (selected === "Pesquisadores") {
            var found = [];
            setArgument(value)
            if (argument === "") {
                setPesquisadores(allPesquisadores);
                return;
            }
            for (let index = 0; index < allPesquisadores.length; index++) {
                var element = allPesquisadores[index];
                if (element.toLowerCase().includes(value.toLowerCase())) {
                    found.push(allPesquisadores[index]);
                }
            }
            setPesquisadores(found)
            return
        }
        if (selected === "Publicações") {
            var found = [];
            setArgument(value)
            if (argument === "") {
                setPublicacoes(allPublicacoes);
                return;
            }
            for (let index = 0; index < allPublicacoes.length; index++) {
                var element = allPublicacoes[index];
                if (element.toLowerCase().includes(value.toLowerCase())) {
                    found.push(allPublicacoes[index]);
                }
            }
            setPublicacoes(found);
            return;
        }
        if (selected === "Pesquisas") {
            var found = [];
            setArgument(value)
            if (argument === "") {
                setPesquisas(allPesquisas);
                return;
            }
            for (let index = 0; index < allPesquisas.length; index++) {
                var element = allPesquisas[index];
                if (element.toLowerCase().includes(value.toLowerCase())) {
                    found.push(allPesquisas[index]);
                }
            }
            setPesquisas(found);
            return;
        }
    }
    return (
        <>
            <Navbar name="+ Sobre Nós" />
            <div className="sobre-nos-body" >
                <div className="sobre-nos-buttons-big" >
                    <h3 onClick={() => setSelected("Pesquisadores")} className={selected === "Pesquisadores" ? "sobre-nos-option-selected-big" : "sobre-nos-option-big"} >Pesquisadores</h3>
                    <h3 onClick={() => setSelected("Pesquisas")} className={selected === "Pesquisas" ? "sobre-nos-option-selected-big" : "sobre-nos-option-big"} >Pesquisas</h3>
                    <h3 onClick={() => setSelected("Publicações")} className={selected === "Publicações" ? "sobre-nos-option-selected-big" : "sobre-nos-option-big"} >Publicações</h3>
                </div>
                <div className="sobre-nos-buttons-small">
                    <div onClick={() => setSelected("Pesquisadores")} className={selected === "Pesquisadores" ? "sobre-nos-option-selected-big" : "sobre-nos-option-big"} />
                    <div onClick={() => setSelected("Pesquisas")} className={selected === "Pesquisas" ? "sobre-nos-option-selected-big" : "sobre-nos-option-big"} />
                    <div onClick={() => setSelected("Publicações")} className={selected === "Publicações" ? "sobre-nos-option-selected-big" : "sobre-nos-option-big"} />
                </div>
                <div className="sobre-nos-selected-indicator">
                    <h2>{selected}</h2>
                </div>
                <div className="sobre-nos-options-big" >
                    <input placeholder={`Buscar em ${selected}`} value={argument} onChange={(e) => search(e.target.value)} className="input-sobre-nos" />
                    {
                        selected === "Pesquisadores" ? <Pesquisadores data={pesquisadores} /> : selected === "Pesquisas" ? <Pesquisas data={pesquisas} /> : <Publicacoes data={publicacoes} />
                    }
                </div>
            </div>
            <Helmet>
                <title>+ Sobre Nós</title>
                <meta name="description" content="Mais sobre nós O atlas de novas cidades" />
            </Helmet>
            <Button />
        </>
    );
};