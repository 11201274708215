import React from "react";
import { useState, useEffect } from "react";
import Navbar from "./../../../components/navbar";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import firebase from "./../../../services/initFirebase";
import Page from "./page";
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function Avaliacao() {
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [open, setOpen] = React.useState(false);
  const [severity, setSeverity] = useState("success");
  const [message, setMessage] = useState("");
  const [credential, setcredential] = useState("");

  useEffect(() => {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        setcredential(user.email);
      }
    });
  }, []);
  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const submit = () => {
    if (email === "") {
      setSeverity("info");
      setMessage("Insira um e-mail");
      handleClick();
      return;
    }
    if (password === "") {
      setSeverity("info");
      setMessage("Insira uma senha");
      handleClick();
      return;
    }
    firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then((res) => {
        setSeverity("success");
        setMessage("Verificação realizada com sucesso!");
        handleClick();
      })
      .catch((err) => {
        setSeverity("error");
        setMessage("Ocorreu uma falha na verificação, tente novamente");
        handleClick();
      });
  };
  return (
    <>
      <Navbar name="Conteúdo" />
      {credential !== "" ? (
        <Page />
      ) : (
        <div className="avaliacao-input-wrapper">
          <input
            placeholder="E-mail"
            className="avaliacao-narrativas-input"
            type="text"
            onChange={(e) => setEmail(e.target.value)}
          />
          <input
            placeholder="Senha"
            className="avaliacao-narrativas-input"
            type="text"
            onChange={(e) => setPassword(e.target.value)}
          />
          <button
            onClick={() => submit()}
            className="avaliacao-narrativas-button"
          >
            Enviar
          </button>
        </div>
      )}

      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={severity}>
          {message}
        </Alert>
      </Snackbar>
    </>
  );
}
