import React, { useState } from 'react';
import './styles.css';
import Compare from './compare';
import Navbar from './../../components/navbar';
import Instructions from './instructions';
import Narrativa from './../../components/quiz/narrativa';
import firebase from './../../services/initFirebase';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Button from './../../components/button';
import Map from './map';
import Helmet from 'react-helmet';
function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function Dispositivo() {
    const [option, setOption] = useState("Instruções");
    const [isNarrativa, setIsNarrativa] = useState(false);
    const [flipped, setFlipped] = useState(false);
    const [start, setStart] = useState(false)
    const [cities, setCities] = useState([]);
    const [number, setNumber] = useState(2);
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [narrativa, setNarrativa] = useState("");
    const [selectedOption, setSelectedOption] = useState("Descrição das cidades");
    const [open, setOpen] = useState(false);
    const [severity, setSeverity] = useState("success");
    const [message, setMessage] = useState("");
    const handleClick = () => {
        setOpen(true);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    const _fetch_cities = async () => {
        var citiesFetched = [];
        var randomCities = [];
        var loops = [];
        loops.length = number;
        await firebase.firestore().collection("cidades").get().then(snapshot => {
            snapshot.forEach((doc) => {
                citiesFetched.push(doc.data());
            })
        }).then(() => {
            var sortedRands = [];
            for (let idx = 0; idx < loops.length; idx++) {
                var rand = Math.floor(Math.random() * citiesFetched.length);
                if (!sortedRands.includes(rand)) {
                    sortedRands.push(rand);
                    randomCities.push(citiesFetched[rand])
                } else {
                    idx = idx - 1;
                }

            }
            setCities(randomCities)
        }).then(() => {
            setStart(true);
            setTimeout(() => {
                setFlipped(true);
            }, 2000);
        })
    }

    const _submit = async () => {
        var cityNames = [];
        for (let index = 0; index < cities.length; index++) {
            cityNames.push(cities[index].name);
        }
        firebase.firestore().collection("narrativas").add({
            name: name,
            narrativa: narrativa,
            email: email,
            comparedCities: cities,
            cityNames: cityNames,
            id: "",
            status: "Analisar",
        }).then((newDoc) => {
            firebase.firestore().collection("narrativas").doc(newDoc.id).update({ id: newDoc.id }).then(() => {
                setName("");
                setEmail("");
                setNarrativa("");
                setSeverity("success");
                setMessage("Narrativa cadastrada com sucesso")
                handleClick();
            })
        }).catch((err) => {
            setSeverity("error");
            setMessage("Ocorreu um erro ao cadastrar a narrativa, tente novamente mais tarde")
            handleClick();
        })
    }
    return (
        <>
            <Navbar name="Dispositivo - Atlas" />
            {
                selectedOption !== "Narrativa" ?
                    <>
                        <div className="instructions-wrapper">
                            <div className="instructions-title-wrapper">
                                <h2 onClick={() => setOption("Instruções")} className={option === "Instruções" ? "instructions-title" : "instructions-sub-title"} >Instruções</h2>
                                <h2 onClick={() => setOption("Compare")} className={option === "Compare" ? "instructions-title" : "instructions-sub-title"} >Compare</h2>
                            </div>
                            {
                                option === 'Instruções' ? <Instructions /> : <Compare setSelectedOption={(Receivedoption) => setSelectedOption(Receivedoption)} selectedOption={selectedOption} setOption={(value) => setNumber(value)} option={number} start={start} flipped={flipped} _fetch_cities={() => _fetch_cities()} cities={cities} setNarrativa={() => setIsNarrativa(!isNarrativa)} />
                            }
                        </div>
                        <div className="instructions-wrapper-small">
                            <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                <div className="small-option-button-wrappers">
                                    <div onClick={() => {
                                        setOption("Instruções")
                                    }} className={option === "Instruções" ? "small-option-button-selected" : "small-option-button"} />
                                    <div onClick={() => {
                                        setOption("Compare")
                                    }} className={option === "Compare" ? "small-option-button-selected" : "small-option-button"} />
                                </div>
                                <h3>{option}</h3>
                            </div>
                            {
                                option === 'Instruções' ? <Instructions /> : <Compare setSelectedOption={(Receivedoption) => {
                                    setSelectedOption(Receivedoption)
                                }} selectedOption={selectedOption} setOption={(value) => setNumber(value)} option={number} start={start} flipped={flipped} _fetch_cities={() => _fetch_cities()} cities={cities} setNarrativa={() => setIsNarrativa(!isNarrativa)} />
                            }
                        </div>
                    </> : <Narrativa setNarrativa={() => setIsNarrativa(!isNarrativa)} setSelectedOption={(option) => {
                        setSelectedOption(option)
                    }} selectedOption={selectedOption} cities={cities} name={name} setName={(value) => setName(value)} handleNarrativa={(value) => setNarrativa(value)} narrativa={narrativa} email={email} setEmail={(value) => setEmail(value)} _submit={() => _submit()} />
            }
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity={severity}>
                    {message}
                </Alert>
            </Snackbar>
            <Button />
            <Helmet>
                    <title>Dispositivo - Atlas</title>
                    <meta name="description" content="Dispositivo - Atlas O atlas de novas cidades" />
                </Helmet>
        </>
    )
}