import { useState, useEffect } from "react";
import firebase from "./../../services/initFirebase";
export default function Content({ handleClick, setSeverity, setMessage }) {
  const [analisar, setAnalisar] = useState([]);
  const [aprovadas, setAprovadas] = useState([]);
  const [recusadas, setRecusadas] = useState([]);
  const control = 0;
  const fetechData = () => {
    firebase
      .firestore()
      .collection("narrativas")
      .onSnapshot((snapshot) => {
        setAnalisar([]);
        setAprovadas([]);
        setRecusadas([]);
        snapshot.forEach((data) => {
          if (data.data().status === "Analisar") {
            setAnalisar((analisar) => analisar.concat(data.data()));
          }
          if (data.data().status === "Aprovada") {
            setAprovadas((aprovadas) => aprovadas.concat(data.data()));
          }
          if (data.data().status === "Recusada") {
            setRecusadas((recusadas) => recusadas.concat(data.data()));
          }
        });
      });
  };

  const listNames = (cities) => {
    return cities.map((element, id) => (
      <>
        {id === cities.length - 1 ? (
          <p className="city-name-element-last">{element}</p>
        ) : (
          <p className="city-name-element">{element}</p>
        )}
      </>
    ));
  };

  const refuse = (id) => {
    firebase
      .firestore()
      .collection("narrativas")
      .doc(id)
      .update({ status: "Recusada" })
      .then(() => {
        setMessage("Narrativa recusada com sucesso!");
        setSeverity("success");
        handleClick();
      });
  };

  const approve = (id) => {
    firebase
      .firestore()
      .collection("narrativas")
      .doc(id)
      .update({ status: "Aprovada" })
      .then(() => {
        setMessage("Narrativa aprovada com sucesso!");
        setSeverity("success");
        handleClick();
      });
  };

  const listAprovadas = aprovadas.map((element, id) => (
    <div className="aprovadas-narrativa-body" key={id + element.name}>
      <div className="narrativa-element-divider" />
      <div className="list-name-wrapper">{listNames(element.cityNames)}</div>
      <p>{element.email}</p>
      <p>{element.name}</p>
      <p>{element.narrativa}</p>
      <div className="aprovadas-button-wrapper">
        <button className="refuse-button" onClick={() => refuse(element.id)}>
          Recusar
        </button>
      </div>
    </div>
  ));
  const listAnalisar = analisar.map((element, id) => (
    <div className="analisar-narrativa-body" key={id + element.name}>
      <div className="narrativa-element-divider" />
      <div className="list-name-wrapper">{listNames(element.cityNames)}</div>
      <p>{element.email}</p>
      <p>{element.name}</p>
      <p>{element.narrativa}</p>
      <div className="analisar-button-wrapper">
        <button className="refuse-button" onClick={() => refuse(element.id)}>
          Recusar
        </button>
        <button className="approve-button" onClick={() => approve(element.id)}>
          Aprovar
        </button>
      </div>
    </div>
  ));
  const listRecusadas = recusadas.map((element, id) => (
    <div className="recusada-narrativa-body" key={id + element.name}>
      <div className="narrativa-element-divider" />
      <div className="list-name-wrapper">{listNames(element.cityNames)}</div>
      <p>{element.email}</p>
      <p>{element.name}</p>
      <p>{element.narrativa}</p>
      <div className="recusada-button-wrapper">
        <button className="approve-button" onClick={() => approve(element.id)}>
          Aprovar
        </button>
      </div>
    </div>
  ));
  useEffect(() => {
    fetechData();
  }, [control]);
  return (
    <>
      <h2 className="narrativas-avaliacao-header">Narrativas</h2>
      <div className="narrativas-a-analisar-wrapper">
        <h3>A analisar</h3>
        {listAnalisar}
      </div>
      <div className="narrativas-aprovadas-wrapper">
        <h3>Aprovadas</h3>
        {listAprovadas}
      </div>
      <div className="narrativas-recusadas-wrapper">
        <h3>Recusadas</h3>
        {listRecusadas}
      </div>
    </>
  );
}
