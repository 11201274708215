import { useState, useEffect } from 'react';
import firebase from './../../../services/initFirebase';
import List from './list/pesquisas'
export default function Pesquisadores() {
    const [data, setData] = useState([]);

    const getData = () => {
        setData([])
        firebase.firestore().collection("Pesquisa").onSnapshot(snapshot => {
            setData([])
            snapshot.forEach((doc) => {
                setData(data => data.concat(doc.data()));
            })
        })
    }
    const control = 0;
    useEffect(() => {
        getData();
    }, [])

    const listData = data.map((item, index) => (
        <List data={item} key={index + item.id} />
    ))
    return (
        <>
            <h3 className="publicacoes-header">Pesquisas</h3>
            {listData}
        </>
    )
}