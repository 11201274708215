import "./styles.css";
import marked from "marked";

export default function Researches({ text }) {
  const renderText = () => {
    const __html = marked(text, { sanitize: true });
    return { __html };
  };
  return (
    <>
      <div className="researches-card-body">
        <div dangerouslySetInnerHTML={renderText()} />
      </div>
    </>
  );
}
