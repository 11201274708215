import 'firebase/storage';

require("firebase/functions");
var firebase = require("firebase/app");

require("firebase/auth");
require("firebase/firestore");
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyD02xzvoj7VVEEznrfAHYodUvMUcbX70xU",
    authDomain: "atlas-de-cidades.firebaseapp.com",
    projectId: "atlas-de-cidades",
    storageBucket: "atlas-de-cidades.appspot.com",
    messagingSenderId: "757436248250",
    appId: "1:757436248250:web:57a6a70d21cb7fa86b1283",
    measurementId: "G-9CREDRN690"
};

// Initialize Firebase
firebase.default.initializeApp(firebaseConfig);
firebase.default.auth()

export default !firebase.default.apps.length ? firebase.default.initializeApp(firebaseConfig) : firebase.default.app();
