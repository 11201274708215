import { useState, useEffect, useReducer } from "react";
import Navbar from "./../../components/navbar";
import Map from "./../../components/map";
import Researches from "./../../components/researches";
import CityItem from "./../../components/citiyItem";
import firebase from "./../../services/initFirebase";
import CityDetails from "./../../components/cityDetails";
import Button from "./../../components/button";
import polygon from "./../../assets/images/polygon.png";
import Helmet from "react-helmet";
import orangeArrow from "./../../assets/images/orange-arrow-small.png";
import whiteArrow from "./../../assets/images/white-arrow.png";
import Region from "./filters/region";
import Timing from "./filters/timing";
import Funcao from "./filters/function";
import Project from "./filters/project";
import Professional from "./filters/professional";
import Entrepeneur from "./filters/entrepeneur";

export default function Oatlas() {
  const [selectedOption, setSelectedOption] = useState("As pesquisas");
  const [list, setList] = useState([]);
  const [selectedCity, setSelectedCity] = useState("");
  const [cell, setCell] = useState(1);
  const [openFilter, setOpenFilter] = useState(false);
  const [cityData, setCityData] = useState({});
  const [showRegiao, setShowRegiao] = useState(false);
  const [showPeriodo, setShowPeriodo] = useState(false);
  const [showFuncao, setShowFuncao] = useState(false);
  const [showEmpreendedor, setShowEmpreendedor] = useState(false);
  const [showProfissional, setShowProfissional] = useState(false);
  const [showProjeto, setShowProjeto] = useState(false);
  const [filteredCities, setFilterdCities] = useState([]);
  const [text, setText] = useState("");
  const [filter, setFilter] = useState({
    regiao: {
      Norte: false,
      Nordeste: false,
      "Centro-Oeste": false,
      Sudeste: false,
      Sul: false,
    },
    periodo: {
      "1889 - 1899": false,
      "1900 - 1909": false,
      "1910 - 1919": false,
      "1920 - 1929": false,
      "1930 - 1939": false,
      "1940 - 1949": false,
      "1950 - 1959": false,
      "1960 - 1969": false,
      "1970 - 1979": false,
      "1980 - 1989": false,
      "1990 - 1999": false,
      "2000 - 2009": false,
      "2010 - 2019": false,
    },
    funcao: {
      Colonização: false,
      Cidade: false,
      Defesa: false,
      Recreativa: false,
      Saúde: false,
      Empresarial: false,
      Administrativa: false,
      Satélite: false,
      Expansão: false,
      Empresarial: false,
      Religiosa: false,
      Colônia: false,
      Portuária: false,
      Ferroviária: false,
      Rodoviária: false,
      Infraestrutural: false,
      Instituição: false,
      "Campo para refugiados": false,
      "Cidade dormitório": false,
      Intelectual: false,
      Tecnologia: false,
      "Cidade privada": false,
      Outra: false,
    },
    empreendedor: {
      Público: false,
      Privado: false,
      Ambos: false,
    },

    profissional: {
      Arquiteto: false,
      "Engenheiro civil": false,
      "Engenheiro sanitarista": false,
      "Engenheiro agrônomo": false,
      Agrimensor: false,
      Topógrafo: false,
      Urbanista: false,
      Planejador: false,
      Geógrafo: false,
      Paisagista: false,
      Técnico: false,
      "Não identificado": false,
      Outro: false,
    },
    projeto: {
      Modernista: false,
      "Cidade-Jardim": false,
      "Cidade Monumental": false,
      "City Beautiful": false,
      "Cidade Artística": false,
      "Cidade Compacta": false,
      "Cidade Linear": false,
      "Cidade Radiocêntrica": false,
      "Malha ortogonal": false,
      Outro: false,
    },
  });
  const [, forceUpdate] = useReducer((x) => x + 1, 0);

  var control = 0;

  const getCities = () => {
    setList([]);
    var list_1 = [];
    var filteredList = [];
    firebase
      .firestore()
      .collection("cidades")
      .onSnapshot((snapshot) => {
        setList([]);
        list_1 = [];
        filteredList = [];
        if (snapshot.empty) {
          return;
        }
        snapshot.forEach((doc) => {
          list_1.push(doc.data());
          filteredList.push(doc.data());
        });
        list_1.sort(function (a, b) {
          return a.name.localeCompare(b.name);
        });
        filteredList.sort(function (a, b) {
          return a.name.localeCompare(b.name);
        });
        setList(list_1);
        setFilterdCities(filteredList);
      });
  };

  const getText = () => {
    firebase
      .firestore()
      .collection("content")
      .doc("content")
      .onSnapshot((doc) => {
        setText(doc.data().oAtlas);
      });
  };
  useEffect(() => {
    getCities();
    getText();
  }, [control]);

  const searchFilter = () => {
    if (list.length > 0) {
      var results = [];
      var regiao = [];
      var periodo = [];
      var funcao = [];
      var empreendedor = [];
      var profissional = [];
      var projeto = [];
      var regiaoKeys = Object.keys(filter.regiao);
      var periodoKeys = Object.keys(filter.periodo);
      var funcaoKeys = Object.keys(filter.funcao);
      var empreendedorKeys = Object.keys(filter.empreendedor);
      var profissionalKeys = Object.keys(filter.profissional);
      var projetoKeys = Object.keys(filter.projeto);
      regiaoKeys.forEach((data) => {
        if (filter.regiao[data] === true) {
          regiao.push(data);
        }
      });
      var found = false;
      periodoKeys.map((data, index) => {
        if (index === periodoKeys.length - 1) {
        }
        if (filter.periodo[data] === true) {
          periodo.push(data);
        }
      });
      funcaoKeys.forEach((data) => {
        if (filter.funcao[data] === true) {
          funcao.push(data);
        }
      });
      empreendedorKeys.forEach((data) => {
        if (filter.empreendedor[data] === true) {
          empreendedor.push(data);
        }
      });
      profissionalKeys.forEach((data) => {
        if (filter.profissional[data] === true) {
          profissional.push(data);
        }
      });
      projetoKeys.forEach((data) => {
        if (filter.projeto[data] === true) {
          projeto.push(data);
        }
      });
      var timingArray = [];
      var funcaoArray = [];
      var regiaoArray = [];
      var empreendedorArray = [];
      var profissionalArray = [];
      var projetoArray = [];

      if (regiao.length > 0) {
        for (let index = 0; index < list.length; index++) {
          const element = list[index];
          var found = false;
          if (
            element.regiao.some((r) => regiao.includes(r)) &&
            found === false
          ) {
            regiaoArray.push(element);
            found = true;
          } else {
            if (element.regiao.length < 1) {
              regiaoArray.push(element);
            }
          }
        }
      } else {
        regiaoArray = list;
      }
      if (periodo.length > 0) {
        for (let index = 0; index < regiaoArray.length; index++) {
          const element = regiaoArray[index];
          var found = false;
          for (let idx = 0; idx < periodo.length; idx++) {
            const elementPeriodo = periodo[idx];
            if (
              elementPeriodo.split(" - ")[0] <= element.periodo &&
              elementPeriodo.split(" - ")[1] >= element.periodo &&
              found === false
            ) {
              timingArray.push(element);
              found = true;
            } else {
              if (element.periodo === "") {
                timingArray.push(element);
              }
            }
          }
        }
      } else {
        timingArray = regiaoArray;
      }
      if (funcao.length > 0) {
        for (let index = 0; index < timingArray.length; index++) {
          const element = timingArray[index];
          var found = false;
          if (
            element.funcao.some((r) => funcao.includes(r)) &&
            found === false
          ) {
            funcaoArray.push(element);
            found = true;
          } else {
            if (element.funcao.length < 1) {
              funcaoArray.push(element);
            }
          }
        }
      } else {
        funcaoArray = timingArray;
      }
      if (empreendedor.length > 0) {
        for (let index = 0; index < funcaoArray.length; index++) {
          const element = funcaoArray[index];
          var found = false;
          if (
            element.Empreendendor.some((r) => empreendedor.includes(r)) &&
            found === false
          ) {
            empreendedorArray.push(element);
            found = true;
          } else {
            if (element.Empreendendor.length < 1) {
              empreendedorArray.push(element);
            }
          }
        }
      } else {
        empreendedorArray = funcaoArray;
      }
      if (profissional.length > 0) {
        for (let index = 0; index < empreendedorArray.length; index++) {
          const element = empreendedorArray[index];
          var found = false;
          if (
            element.profissional.some((r) => profissional.includes(r)) &&
            found === false
          ) {
            profissionalArray.push(element);
            found = true;
          } else {
            if (element.profissional.length < 1) {
              profissionalArray.push(element);
            }
          }
        }
      } else {
        profissionalArray = empreendedorArray;
      }
      if (projeto.length > 0) {
        for (let index = 0; index < profissionalArray.length; index++) {
          const element = profissionalArray[index];
          var found = false;
          if (
            element.Projeto.some((r) => projeto.includes(r)) &&
            found === false
          ) {
            projetoArray.push(element);
            found = true;
          } else {
            if (element.Projeto.length < 1) {
              projetoArray.push(element);
            }
          }
        }
      } else {
        projetoArray = profissionalArray;
      }
      setFilterdCities(projetoArray);
      forceUpdate();
    }
  };
  useEffect(() => {
    searchFilter();
  }, [
    filter.periodo,
    filter.empreendedor,
    filter.funcao,
    filter.profissional,
    filter.projeto,
    filter.regiao,
  ]);
  const showLabel = (index) => {
    setFilterdCities(
      filteredCities.map((item) =>
        item.id === index ? { ...item, showLabel: true } : item
      )
    );
  };

  const hideLabel = (index) => {
    setFilterdCities(
      filteredCities.map((item) =>
        item.name === index ? { ...item, showLabel: false } : item
      )
    );
  };

  const selectCity = (id, data) => {
    if (id === selectedCity) {
      setSelectedCity("");
      setFilterdCities(
        filteredCities.map((item) =>
          item.id === id ? { ...item, showLabel: false } : item
        )
      );
      setCityData({});
    } else {
      setSelectedCity(id);
      setFilterdCities(
        filteredCities.map((item) =>
          item.name === id ? { ...item, showLabel: true } : item
        )
      );
      setCityData(data);
    }
  };
  const listCities = filteredCities.map((city, index) => (
    <CityItem
      selectCity={() => {
        selectCity(city.name, city);
      }}
      selectedCity={selectedCity}
      hideLabel={() => hideLabel(city.name)}
      showLabel={() => showLabel(city.name)}
      key={index}
      position={city.coordinates}
      id={city.id}
      name={city.name}
    />
  ));
  
  const cellIndicators = (
    <>
      <div className="cell-indicators-wrapper">
        <div
          onClick={() => setCell(1)}
          className={cell === 1 ? "cell-indicator-selected" : "cell-indicator"}
        ></div>
        <div
          onClick={() => setCell(2)}
          className={cell === 2 ? "cell-indicator-selected" : "cell-indicator"}
        ></div>
      </div>
    </>
  );
  return (
    <>
      <Navbar name={"O Atlas"} />
      <div className="small-cell-container">
        <div style={{ display: "flex", flexDirection: "row" }}>
          {window.innerWidth < 600 ? cellIndicators : null}
        </div>
        <h3 className="o-atlas-cell-title">
          {cell === 2 ? "As cidades" : "As pesquisas"}
        </h3>
        {cell === 1 ? (
          <>
            <div className="o-atlas-body">
              <div className="o-atlas-right-container">
                <Researches text={text} />
              </div>
            </div>
          </>
        ) : (
          <>
            {selectedCity !== "" ? (
              <CityDetails data={cityData} />
            ) : (
              <div className="cities-list-card-body">{listCities}</div>
            )}
          </>
        )}
      </div>

      <div className="big-cell-wrapper">
        {selectedCity === "" ? (
          <>
            <div className="o-atlas-body">
              <div
                className={
                  selectedCity === ""
                    ? "o-atlas-left-container"
                    : "o-atlas-left-container-details"
                }
              >
                <div className="filter-button-wrapper">
                  <button
                    className="filter-button-container"
                    onClick={() => setOpenFilter((old) => !old)}
                  >
                    <p>FILTROS </p>{" "}
                    <img
                      alt=""
                      className="filter-button-white-arrow"
                      src={whiteArrow}
                    />
                  </button>
                  {openFilter === true ? (
                    <div className="filter-button-options-wrapper">
                      <div
                        onMouseEnter={() => setShowRegiao(true)}
                        onMouseLeave={() => setShowRegiao(false)}
                        setFilter={(data) => {
                          setFilter(data);
                          searchFilter();
                        }}
                      >
                        <button className="filter-button-container-item">
                          <p>REGIÃO </p>{" "}
                          <img
                            alt=""
                            className="filter-button-orange-arrow"
                            src={orangeArrow}
                          />
                        </button>
                        <div
                          style={{
                            display: showRegiao === true ? "block" : "none",
                          }}
                          className="dropdown-content"
                        >
                          <Region
                            filter={filter}
                            forceUpdate={() => forceUpdate()}
                            setFilter={(data) => {
                              setFilter(data);
                              searchFilter();
                            }}
                          />
                        </div>
                      </div>
                      <div
                        onMouseEnter={() => setShowPeriodo(true)}
                        onMouseLeave={() => setShowPeriodo(false)}
                      >
                        <button className="filter-button-container-item">
                          <p>PERÍODO </p>{" "}
                          <img
                            alt=""
                            className="filter-button-orange-arrow"
                            src={orangeArrow}
                          />
                        </button>
                        <div
                          style={{
                            display: showPeriodo === true ? "flex" : "none",
                          }}
                          className="dropdown-content-reverse"
                        >
                          <Timing
                            filter={filter}
                            forceUpdate={() => forceUpdate()}
                            setFilter={(data) => {
                              setFilter(data);
                              searchFilter();
                            }}
                          />
                        </div>
                      </div>
                      <div
                        onMouseEnter={() => setShowFuncao(true)}
                        onMouseLeave={() => setShowFuncao(false)}
                      >
                        <button className="filter-button-container-item">
                          <p>FUNÇÃO D. </p>{" "}
                          <img
                            alt=""
                            className="filter-button-orange-arrow"
                            src={orangeArrow}
                          />
                        </button>
                        <div
                          style={{
                            display: showFuncao === true ? "flex" : "none",
                          }}
                          className="dropdown-content"
                        >
                          <Funcao
                            filter={filter}
                            forceUpdate={() => forceUpdate()}
                            setFilter={(data) => {
                              setFilter(data);
                              searchFilter();
                            }}
                          />
                        </div>
                      </div>
                      <div
                        onMouseEnter={() => setShowEmpreendedor(true)}
                        onMouseLeave={() => setShowEmpreendedor(false)}
                      >
                        <button className="filter-button-container-item">
                          <p>EMPREENDEDOR </p>{" "}
                          <img
                            alt=""
                            className="filter-button-orange-arrow"
                            src={orangeArrow}
                          />
                        </button>
                        <div
                          style={{
                            display:
                              showEmpreendedor === true ? "block" : "none",
                          }}
                          className="dropdown-content"
                        >
                          <Entrepeneur
                            filter={filter}
                            forceUpdate={() => forceUpdate()}
                            setFilter={(data) => {
                              setFilter(data);
                              searchFilter();
                            }}
                          />{" "}
                        </div>
                      </div>
                      <div
                        onMouseEnter={() => setShowProfissional(true)}
                        onMouseLeave={() => setShowProfissional(false)}
                      >
                        <button className="filter-button-container-item">
                          <p>PROFISSIONAL </p>{" "}
                          <img
                            alt=""
                            className="filter-button-orange-arrow"
                            src={orangeArrow}
                          />
                        </button>
                        <div
                          style={{
                            display:
                              showProfissional === true ? "flex" : "none",
                          }}
                          className="dropdown-content"
                        >
                          <Professional
                            filter={filter}
                            forceUpdate={() => forceUpdate()}
                            setFilter={(data) => {
                              setFilter(data);
                              searchFilter();
                            }}
                          />{" "}
                        </div>
                      </div>
                      <div
                        onMouseEnter={() => setShowProjeto(true)}
                        onMouseLeave={() => setShowProjeto(false)}
                      >
                        <button className="filter-button-container-item">
                          <p>PROJETO </p>{" "}
                          <img
                            alt=""
                            className="filter-button-orange-arrow"
                            src={orangeArrow}
                          />
                        </button>
                        <div
                          style={{
                            display: showProjeto === true ? "flex" : "none",
                          }}
                          className="dropdown-content"
                        >
                          <Project
                            filter={filter}
                            forceUpdate={() => forceUpdate()}
                            setFilter={(data) => {
                              setFilter(data);
                              searchFilter();
                            }}
                          />{" "}
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>
                <Map list={filteredCities} />
              </div>
              <div className="o-atlas-right-container">
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <button
                    onClick={() => {
                      setSelectedOption("As pesquisas");
                    }}
                    className={
                      selectedOption === "As pesquisas"
                        ? "o-atlas-page-buttons-selected"
                        : "o-atlas-page-buttons"
                    }
                  >
                    As pesquisas
                  </button>
                  <button
                    onClick={() => {
                      setSelectedOption("As cidades");
                    }}
                    className={
                      selectedOption === "As cidades"
                        ? "o-atlas-page-buttons-selected"
                        : "o-atlas-page-buttons"
                    }
                  >
                    As cidades
                  </button>
                </div>
                {selectedOption === "As pesquisas" ? (
                  <Researches text={text} />
                ) : (
                  <div className="cities-list-card-body">{listCities}</div>
                )}
              </div>
            </div>
          </>
        ) : (
          <>
            <CityDetails data={cityData} />
          </>
        )}
      </div>
      {selectedCity === "" ? null : (
        <button
          style={{ zIndex: 2 }}
          className="back-button-wrapper"
          onClick={() => {
            setFilterdCities(
              filteredCities.map((item) =>
                item.name === selectedCity
                  ? { ...item, showLabel: false }
                  : item
              )
            );
            setSelectedCity("");
          }}
        >
          <img alt="" src={polygon} />
        </button>
      )}
      <Helmet>
        <title>O atlas</title>
        <meta name="description" content="O atlas de novas cidades" />
      </Helmet>
      <Button />
    </>
  );
}
