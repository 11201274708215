import './styles.css';
import Arrow from './../../components/arrow';
export default function Instructions({ }) {
    var purpleBoxes = [1, 2, 3];
    const showPurpleBoxes = purpleBoxes.map((box, index) => {
        return (
            <div className="instructions-arranjo-box" />
        );
    });
    var whiteLines = [1, 2, 3, 4, 5, 6, 7, 8];
    const showWhiteLines = whiteLines.map((line, idx) => (
        <>
            <div className="instructions-white-line" />
        </>
    ))
    return (
        <>
            <div className="instructions-cards-wrapper">
                <div className="instruction-cards-elements-wrapper">
                    <h3 className="instructions-cards-titles">Arranjo</h3>
                    <div className="instructions-arranjo-box-wrapper">
                        {
                            showPurpleBoxes
                        }
                    </div>
                    <p className="instructions-text">De acordo com sua escolha, algumas cidades, aleatoriamente, serão arranjadas em grupos de 2, 3 ou 5.</p>
                </div>
                <div className="instruction-cards-elements-wrapper">
                    <h3 className="instructions-cards-titles">Arranjo</h3>
                    <div className="instructions-arranjo-box-wrapper">
                        <div className="instructions-arranjo-box" />
                        <div className="instructions-arrows-wrapper" >
                            <div className="correct-direction-arrow">
                                <Arrow />
                            </div>
                            <div className="reverse-arrow">
                                <Arrow />
                            </div>
                        </div>
                        <div className="instructions-arranjo-box" />
                    </div>
                    <p className="instructions-text">Compare as informações e características das cidades, notando suas similaridades e diferenças.</p>
                </div>
                <div className="instruction-cards-elements-wrapper">
                    <h3 className="instructions-cards-titles">Narrativa</h3>
                    <div className="instructions-narrativa-box-wrapper">
                        {showWhiteLines}
                    </div>
                    <p className="instructions-text">Escreva sua comparação e divulgue essa narrativa para o site.</p>
                </div>
            </div>
        </>
    );
};