import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
export default function Filter({ filter, setFilter, forceUpdate }) {
  return (
    <div style={{ columnCount: 4 }}>
      <FormControlLabel
        control={
          <Checkbox
            color="#ffffff"
            checked={filter.profissional.Arquiteto}
            onChange={() => {
              var data = filter;
              data.profissional.Arquiteto = !data.profissional.Arquiteto;
              setFilter(data);
              forceUpdate();
            }}
            name="Norte"
          />
        }
        label={
          <p style={{ fontFamily: "quantico", whiteSpace: "nowrap" }}>
            Arquiteto
          </p>
        }
      />
      <FormControlLabel
        control={
          <Checkbox
            color="#ffffff"
            checked={filter.profissional["Engenheiro civil"]}
            onChange={() => {
              var data = filter;
              data.profissional["Engenheiro civil"] =
                !data.profissional["Engenheiro civil"];
              setFilter(data);
              forceUpdate();
            }}
            name="Norte"
          />
        }
        label={
          <p style={{ fontFamily: "quantico", whiteSpace: "nowrap" }}>
            Engenheiro civil
          </p>
        }
      />
      <FormControlLabel
        control={
          <Checkbox
            color="#ffffff"
            checked={filter.profissional["Engenheiro sanitarista"]}
            onChange={() => {
              var data = filter;
              data.profissional["Engenheiro sanitarista"] =
                !data.profissional["Engenheiro sanitarista"];
              setFilter(data);
              forceUpdate();
            }}
            name="Norte"
          />
        }
        label={
          <p style={{ fontFamily: "quantico", whiteSpace: "nowrap" }}>
            Engenheiro sanitarista
          </p>
        }
      />
      <FormControlLabel
        control={
          <Checkbox
            color="#ffffff"
            checked={filter.profissional["Engenheiro agrônomo"]}
            onChange={() => {
              var data = filter;
              data.profissional["Engenheiro agrônomo"] =
                !data.profissional["Engenheiro agrônomo"];
              setFilter(data);
              forceUpdate();
            }}
            name="Norte"
          />
        }
        label={
          <p style={{ fontFamily: "quantico", whiteSpace: "nowrap" }}>
            Engenheiro agrônomo
          </p>
        }
      />
      <FormControlLabel
        control={
          <Checkbox
            color="#ffffff"
            checked={filter.profissional.Agrimensor}
            onChange={() => {
              var data = filter;
              data.profissional.Agrimensor = !data.profissional.Agrimensor;
              setFilter(data);
              forceUpdate();
            }}
            name="Norte"
          />
        }
        label={
          <p style={{ fontFamily: "quantico", whiteSpace: "nowrap" }}>
            Agrimensor
          </p>
        }
      />
      <FormControlLabel
        control={
          <Checkbox
            color="#ffffff"
            checked={filter.profissional.Topógrafo}
            onChange={() => {
              var data = filter;
              data.profissional.Topógrafo = !data.profissional.Topógrafo;
              setFilter(data);
              forceUpdate();
            }}
            name="Norte"
          />
        }
        label={
          <p style={{ fontFamily: "quantico", whiteSpace: "nowrap" }}>
            Topógrafo
          </p>
        }
      />
      <FormControlLabel
        control={
          <Checkbox
            color="#ffffff"
            checked={filter.profissional.Urbanista}
            onChange={() => {
              var data = filter;
              data.profissional.Urbanista = !data.profissional.Urbanista;
              setFilter(data);
              forceUpdate();
            }}
            name="Norte"
          />
        }
        label={
          <p style={{ fontFamily: "quantico", whiteSpace: "nowrap" }}>
            Urbanista
          </p>
        }
      />
      <FormControlLabel
        control={
          <Checkbox
            color="#ffffff"
            checked={filter.profissional.Planejador}
            onChange={() => {
              var data = filter;
              data.profissional.Planejador = !data.profissional.Planejador;
              setFilter(data);
              forceUpdate();
            }}
            name="Norte"
          />
        }
        label={
          <p style={{ fontFamily: "quantico", whiteSpace: "nowrap" }}>
            Planejador
          </p>
        }
      />
      <FormControlLabel
        control={
          <Checkbox
            color="#ffffff"
            checked={filter.profissional.Geógrafo}
            onChange={() => {
              var data = filter;
              data.profissional.Geógrafo = !data.profissional.Geógrafo;
              setFilter(data);
              forceUpdate();
            }}
            name="Norte"
          />
        }
        label={
          <p style={{ fontFamily: "quantico", whiteSpace: "nowrap" }}>
            Geógrafo
          </p>
        }
      />

      <FormControlLabel
        control={
          <Checkbox
            color="#ffffff"
            checked={filter.profissional.Paisagista}
            onChange={() => {
              var data = filter;
              data.profissional.Paisagista = !data.profissional.Paisagista;
              setFilter(data);
              forceUpdate();
            }}
            name="Norte"
          />
        }
        label={
          <p style={{ fontFamily: "quantico", whiteSpace: "nowrap" }}>
            Paisagista
          </p>
        }
      />
      <FormControlLabel
        control={
          <Checkbox
            color="#ffffff"
            checked={filter.profissional.Técnico}
            onChange={() => {
              var data = filter;
              data.profissional.Técnico = !data.profissional.Técnico;
              setFilter(data);
              forceUpdate();
            }}
            name="Norte"
          />
        }
        label={
          <p style={{ fontFamily: "quantico", whiteSpace: "nowrap" }}>
            Técnico
          </p>
        }
      />
      <FormControlLabel
        control={
          <Checkbox
            color="#ffffff"
            checked={filter.profissional["Não identificado"]}
            onChange={() => {
              var data = filter;
              data.profissional["Não identificado"] =
                !data.profissional["Não identificado"];
              setFilter(data);
              forceUpdate();
            }}
            name="Não identificado"
          />
        }
        label={
          <p style={{ fontFamily: "quantico", whiteSpace: "nowrap" }}>
            Não identificado
          </p>
        }
      />
      <FormControlLabel
        control={
          <Checkbox
            color="#ffffff"
            checked={filter.profissional.Outro}
            onChange={() => {
              var data = filter;
              data.profissional.Outro = !data.profissional.Outro;
              setFilter(data);
              forceUpdate();
            }}
            name="Norte"
          />
        }
        label={
          <p style={{ fontFamily: "quantico", whiteSpace: "nowrap" }}>Outro</p>
        }
      />
    </div>
  );
}
