import './styles.css';
import Quiz from './../../components/quiz';
export default function Compare({ setNarrativa, _fetch_cities, flipped, cities, start, setOption, option, setSelectedOption, selectedOption }) {
    return (
        <>
            {
                start === false ?
                    <>
                        <div className="compare-cards-wrapper">
                            <div>
                                <h1 className="small-compare-title">Quantas cidades você quer comparar?</h1>
                            </div>
                            <div className="compare-radio-button-wrapper">
                                <div className="compare-fake-radio-buttons" onClick={() => { setOption(2) }}><div className={option === 2 ? "compare-fake-radio-indicator-selected" : "compare-fake-radio-indicator"} />DUAS</div>
                                <div className="compare-fake-radio-buttons" onClick={() => { setOption(3) }}><div className={option === 3 ? "compare-fake-radio-indicator-selected" : "compare-fake-radio-indicator"} />TRÊS</div>
                                <div className="compare-fake-radio-buttons" onClick={() => { setOption(5) }}><div className={option === 5 ? "compare-fake-radio-indicator-selected" : "compare-fake-radio-indicator"} />CINCO</div>
                            </div>
                            <button onClick={() => {
                                _fetch_cities();
                            }} className="compare-button">ARRANJAR</button>
                        </div>
                    </> : <>
                        <Quiz cities={cities} setNarrativa={setNarrativa} flipped={flipped} selectedOption={selectedOption} setSelectedOption={(Receivedoption) => setSelectedOption(Receivedoption)} />
                    </>
            }
        </>
    );
};