import firebase from "./../../../services/initFirebase";
import { useState, useEffect } from "react";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import CircularProgress from "@material-ui/core/CircularProgress";
import marked from "marked";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function CityItem() {
  const [severity, setSeverity] = useState("success");
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [home, setHome] = useState("");
  const [oAtlas, setOatlas] = useState("");
  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const getText = () => {
    firebase
      .firestore()
      .collection("content")
      .doc("content")
      .onSnapshot((doc) => {
        setHome(doc.data().home);
        setOatlas(doc.data().oAtlas);
      });
  };

  const control = 0;

  useEffect(() => {
    getText();
  }, [control]);
  const update = async () => {
    setIsLoading(true);
    firebase
      .firestore()
      .collection("content")
      .doc("content")
      .set({
        oAtlas: oAtlas,
        home: home,
      })
      .then(() => {
        setIsLoading(false);
        setMessage("Conteúdo atualizado com sucesso!");
        setSeverity("success");
        handleClick();
      })
      .catch(() => {
        setIsLoading(false);
        setMessage("Ocorreu um erro ao cadastrar a cidade!");
        setSeverity("error");
        handleClick();
      });
  };
  const renderText = (text) => {
    const __html = marked(text, { sanitize: true });
    return { __html };
  };
  return (
    <div className="city-item-divider">
      <div className="row-city-item-input">
        <div>
          <span>Página inicial:</span>
          <textarea
            name={"city-período"}
            rows="35"
            className="cronologia-pensamento-input"
            value={home}
            onChange={(e) => setHome(e.target.value)}
          />
        </div>
      </div>
      <div dangerouslySetInnerHTML={renderText(home)} />

      <div className="bottom-city-item-divider" />

      <div className="row-city-item-input">
        <div>
          <span>O atlas:</span>
          <textarea
            name={"city-período"}
            rows="35"
            className="cronologia-pensamento-input"
            value={oAtlas}
            onChange={(e) => setOatlas(e.target.value)}
          />
        </div>
      </div>
      <div dangerouslySetInnerHTML={renderText(oAtlas)} />

      {isLoading === true ? (
        <CircularProgress
          style={{ height: "30px", width: "30px", paddingLeft: "8px" }}
        />
      ) : (
        <button
          onClick={() => update()}
          className="avaliacao-narrativas-button"
        >
          Atualizar
        </button>
      )}
      <br />
      <br />
      <br />
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={severity}>
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
}
