import ListCities from './listCities';
import NewCities from './newcities'

export default function Cities() {
    return (
        <>
            <h2 style={{ paddingLeft: "3vw" }}>Cadastrar novas cidades</h2>
            <NewCities />
            <h2 style={{ paddingLeft: "3vw" }}>Cidades</h2>
            <ListCities />
        </>
    )
}