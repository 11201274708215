import "./styles.css";
import React, { useState } from "react";
import ReactFlip from "react-card-flip";
import card from "./../../assets/images/carta.png";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

export default function Quiz({
  flipped,
  setNarrativa,
  cities,
  setSelectedOption,
  selectedOption,
}) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const [image, setImage] = useState(null);
  const renderCards = (
    <>
      <div className="quiz-card-wrapper">
        {cities.map((city, idx) => (
          <>
            <div key={idx} className="quiz-card-body">
              <img width="auto" height="100%" src={card} alt="" />
            </div>
          </>
        ))}
      </div>
    </>
  );

  const showArray = (list) => {
    return list.map((item, i) => (
      <>
        {i === list.length - 1 ? (
          <p style={{ marginLeft: "5px" }}>{item}</p>
        ) : (
          <p style={{ marginLeft: "5px" }}> {item},</p>
        )}
      </>
    ));
  };

  const renderInfo = (city, i) => {
    return (
      <div key={i} className="quiz-info-card-body">
        <div className="quiz-info-card-title">
          <h3 className="card-title">
            {" "}
            {city.regiao === "CentroOste" ? "Centro Oeste" : city.name}
          </h3>
          <div className="card-title-divider" />
        </div>
        <div className="info-card-body">
          <div className="info-text-line">
            <h5 className="h4-title-card-info">Região: </h5>
            <h5 style={{ fontWeight: "normal" }}>{showArray(city.regiao)}</h5>
          </div>
          <div className="info-text-line">
            <h5 className="info-text-card">Período:</h5>
            <h5 style={{ fontWeight: "normal" }}>{city.periodo}</h5>
          </div>
          <div className="info-text-line">
            <h5 className="info-text-card">Função dominate: </h5>
            <h5 style={{ fontWeight: "normal" }}>{showArray(city.funcao)}</h5>
          </div>
          <div className="info-text-line">
            <h5 className="h4-title-card-info">Empreendedor: </h5>
            <h5 style={{ fontWeight: "normal" }}>
              {showArray(city.Empreendendor)}
            </h5>
          </div>
          <div className="info-text-line">
            <h5 className="h4-title-card-info">Profissional: </h5>
            <h5 style={{ fontWeight: "normal" }}>
              {showArray(city.profissional)}
            </h5>
          </div>
          <div className="info-text-line">
            <h5 className="h4-title-card-info">Projeto: </h5>
            <h5 style={{ fontWeight: "normal" }}>{showArray(city.Projeto)}</h5>
          </div>
          <div
            style={{ display: "flex", justifyContent: "center", width: "100%" }}
          >
            <img
              onClick={() => {
                setImage(city.photo);
                handleOpen();
              }}
              style={{ cursor: "pointer" }}
              className="info-img-wrapper"
              alt=""
              src={city.photo}
            />
          </div>
          <div className="text-description-card">
            <p>{city.text}</p>
          </div>
        </div>
      </div>
    );
  };
  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className="image-modal-content">
            <img src={image} alt="" />
          </div>
        </Fade>
      </Modal>
      <div className="quiz-page-wrapper">
        <div className="quiz-buttons-wrapper">
          <button
            onClick={() => {
              setSelectedOption("Descrição das cidades");
            }}
            className={
              selectedOption === "Descrição das cidades"
                ? "quiz-button-selected"
                : "quiz-button"
            }
          >
            Descrição das cidades
          </button>
          <button
            onClick={() => {
              setSelectedOption("Narrativa");
            }}
            className={
              selectedOption === "Narrativa"
                ? "quiz-button-selected"
                : "quiz-button"
            }
          >
            Narrativa
          </button>
        </div>
        <div className="small-cards-spacing">
          {cities.map((city, index) => (
            <ReactFlip
              key={"card" + index}
              isFlipped={flipped}
              flipDirection="horizontal"
            >
              <div className="quiz-card-body">
                <img width="auto" height="100%" src={card} alt="" />
              </div>
              <div className="quiz-card-wrapper">{renderInfo(city)}</div>
            </ReactFlip>
          ))}
        </div>
      </div>
    </>
  );
}
