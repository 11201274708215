import { useState, useEffect } from "react";
import firebase from "./../../../services/initFirebase";
import CityItem from "./cityItem";
import { Accordion, Typography } from "@material-ui/core";
import { AccordionDetails } from "@material-ui/core";
import { AccordionSummary } from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
export default function ListCities() {
  const [cities, setCities] = useState([]);
  const [next, setNext] = useState("");
  const [last, setLast] = useState("");
  const fetchCities = async () => {
    var query;
    if (next) {
      query = next;
    } else {
      query = firebase
        .firestore()
        .collection("cidades")
        .orderBy("id")
        .limit(25);
    }
    var _snapshot;
    const snapshot = await query.onSnapshot((snapshot_) => {
      setCities([]);
      _snapshot = snapshot_;
      console.log("ss ", snapshot)
      console.log("cidades", snapshot_.docs, snapshot_.size);
      snapshot_.forEach((doc) => {
        setCities((cities) => cities.concat(doc.data()));
      });
    });

    console.log("S", _snapshot);

    // Get the last document
    const last_ = _snapshot.docs[_snapshot.docs.length - 1];
    setLast(last_);
    const next_ = firebase
      .collection("cities")
      .orderBy("id")
      .startAfter(last.data().id)
      .limit(25);
    setNext(next_);

    // Use the query for pagination
    // ...
  };
  const listCities = cities.map((item, index) => (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMore />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography>{item.name}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <CityItem key={item.name + " / " + index} data={item} />
      </AccordionDetails>
    </Accordion>
  ));
  const control = 0;
  useEffect(() => {
    fetchCities();
  }, [control]);
  return (
    <>
      {listCities}
      <button onClick={() => fetchCities()}>Próximo</button>
    </>
  );
}
