export default function Narrativa({ narrativa }) {
  const listCities = narrativa.cityNames.map((element, index) => (
    <div className="narrativa-title-wrapper">
      <h3>{element}</h3>
      {index === narrativa.cityNames.length - 1 ? null : (
        <div className="narrativa-title-divider" />
      )}
    </div>
  ));
  return (
    <>
      <div className="narrativa-list-card-body">
        <div className="narrativa-list-header">
          {narrativa.cityNames ? listCities : null}
        </div>
        <div>
          <p>{narrativa.narrativa}</p>
        </div>
        <div className="narrativa-author-name">
          <p>{narrativa.name}</p>
        </div>
      </div>
    </>
  );
}
