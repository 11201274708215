import NewContent from './newContent';
import Pesquisas from './pesquisas';
import Publicacoes from './publicacoes';
import Pesquisadores from './pesquisadores'

export default function SobreNos() {
    return (
        <>
            <NewContent />
            <Pesquisas />
            <Publicacoes />
            <Pesquisadores />
        </>
    );
};