import line from './../../assets/images/arrow/Line 35.png';
import polygon from './../../assets/images/arrow/Polygon 26.png';
import './styles.css';
export default function Arrow() {
    return (
        <>
            <div className="arrow-wrapper-content">
                <img alt="" src={line} />
                <img  alt="" style={{marginLeft:-3}} src={polygon} />
            </div>
        </>
    );
};
