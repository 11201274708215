import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
export default function Filter({ filter, setFilter, forceUpdate }) {
  const list = [
    "Colonização",
    "Ocupação territorial",
    "Defesa",
    "Recreativa",
    "Saúde",
    "Empresarial",
    "Administrativa",
    "Cidade-satélite",
    "Expansão",
    "Religiosa",
    "Colônia Agrícola",
    "Portuária",
    "Ferroviária",
    "Rodoviária",
    "Infraestrutural",
    "Realocação",
    "Institucional",
    "Campo de refugiados",
    "Cidade-dormitório",
    "Educacional",
    "Tecnológica (Smart city)",
    "Charter City",
  ];
  return (
    <div style={{ columnCount: 4 }}>
      {list.map((item, index) => (
        <FormControlLabel
        key={index + "check-project"}
          control={
            <Checkbox
              color="#ffffff"
              checked={filter.funcao[item]}
              onChange={() => {
                var data = filter;
                data.funcao[item] = !data.funcao[item];
                setFilter(data);
                forceUpdate();
              }}
              name={item}
            />
          }
          label={
            <p style={{ fontFamily: "quantico", whiteSpace: "nowrap" }}>
              {item}
            </p>
          }
        />
      ))}
    </div>
  );
}
