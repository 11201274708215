import firebase from "./../../../services/initFirebase";
import { useState, useReducer } from "react";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import CircularProgress from "@material-ui/core/CircularProgress";
import Region from "./filters/region";
import Timing from "./filters/timing";
import Funcao from "./filters/function";
import Project from "./filters/project";
import Professional from "./filters/professional";
import Entrepeneur from "./filters/entrepeneur";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export default function CityItem() {
  const classes = useStyles();
  const [name, setName] = useState("");
  const [empreendedor, setEmpreendedor] = useState([]);
  const [projeto, setProjeto] = useState([]);
  const [estado, setEstado] = useState("");
  const [text, setText] = useState("");
  const [funcao, setFuncao] = useState([]);
  const [periodo, setPeriodo] = useState(1889);
  const [photo, setPhoto] = useState("");
  const [photoObject, setPhotoObject] = useState({});
  const [position, setPosition] = useState("");
  const [regiao, setRegiao] = useState([]);
  const [profissional, setProfissional] = useState([]);
  const [photoChanged, setPhotoChanged] = useState(false);
  const [severity, setSeverity] = useState("success");
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [link, setLink] = useState("");
  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };
  const update = async () => {
    setIsLoading(true);
    firebase
      .firestore()
      .collection("cidades")
      .doc(name)
      .set({
        Empreendendor: empreendedor,
        Projeto: projeto,
        estado: estado,
        funcao: funcao,
        periodo: periodo,
        name: name,
        position: position,
        regiao: regiao,
        profissional: profissional,
        id: name,
        link: link,
        text: text,
      })
      .then(() => {
        if (photoChanged === true) {
          const storageRef = firebase.storage().ref("cityImamges/");
          const fileRef = storageRef.child(photoObject.name);
          var uploadTask = fileRef.put(photoObject);
          uploadTask.on(
            "state_changed",
            function (snapshot) {
              // Observe state change events such as progress, pause, and resume
              // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
              var progress =
                (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            },
            function (error) {
              // Handle unsuccessful uploads
            },
            function () {
              // Handle successful uploads on complete
              // For instance, get the download URL: https://firebasestorage.googleapis.com/...
              uploadTask.snapshot.ref
                .getDownloadURL()
                .then(function (downloadURL) {
                  firebase
                    .firestore()
                    .collection("cidades")
                    .doc(name)
                    .update({ photo: downloadURL })
                    .then(() => {
                      //handleClick({ vertical: 'top', horizontal: 'right' })
                      setIsLoading(false);
                      setMessage("Cidade cadastrada com sucesso!");
                      setSeverity("success");
                      handleClick();
                    });
                });
            }
          );
        } else {
          setIsLoading(false);
          setMessage("Cidade cadastrada com sucesso!");
          setSeverity("success");
          handleClick();
        }
      })
      .catch(() => {
        setIsLoading(false);
        setMessage("Ocorreu um erro ao cadastrar a cidade!");
        setSeverity("error");
        handleClick();
      });
  };
  var empreendedorItems = ["Público", "Privado", "Ambos"];
  return (
    <div className="city-item-divider">
      <div className="row-city-item-input">
        <div>
          <span>Nome:</span>
          <input
            name={"city-name"}
            className="avaliacao-narrativas-input"
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <div className="multiple-select-wrapper">
          <span>Empreendedor:</span>
          <FormControl className={classes.formControl}>
            <Select
              fullWidth
              labelId="demo-mutiple-name-label"
              id="demo-mutiple-name"
              multiple
              value={empreendedor}
              onChange={(e) => setEmpreendedor(e.target.value)}
              input={<Input />}
              MenuProps={MenuProps}
            >
              {empreendedorItems.map((name) => (
                <MenuItem key={name} value={name}>
                  {name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      </div>

      <div className="row-city-item-input">
        <div className="multiple-select-wrapper">
          <span>Projeto:</span>
          <FormControl className={classes.formControl}>
            <Select
              labelId="demo-mutiple-name-label"
              id="demo-mutiple-name"
              multiple
              value={projeto}
              onChange={(e) => setProjeto(e.target.value)}
              input={<Input />}
              MenuProps={MenuProps}
            >
              {[
                "Modernista",
                "Cidade-Jardim",
                "Cidade Monumental",
                "City Beautiful",
                "Cidade Artística",
                "Cidade Compacta",
                "Cidade Linear",
                "Cidade Radiocêntrica",
                "Malha ortogonal",
                "Outro",
              ].map((name) => (
                <MenuItem key={name} value={name}>
                  {name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div className="multiple-select-wrapper">
          <span>Função:</span>
          <FormControl className={classes.formControl}>
            <Select
              labelId="demo-mutiple-name-label"
              id="demo-mutiple-name"
              multiple
              value={funcao}
              onChange={(e) => setFuncao(e.target.value)}
              input={<Input />}
              MenuProps={MenuProps}
            >
              {[
                "Colonização",
                "Ocupação territorial",
                "Defesa",
                "Recreativa",
                "Saúde",
                "Empresarial",
                "Administrativa",
                "Cidade-satélite",
                "Expansão",
                "Religiosa",
                "Colônia Agrícola",
                "Portuária",
                "Ferroviária",
                "Rodoviária",
                "Infraestrutural",
                "Realocação",
                "Institucional",
                "Campo de refugiados",
                "Cidade-dormitório",
                "Educacional",
                "Tecnológica (Smart city)",
                "Charter City",
              ].map((name) => (
                <MenuItem key={name} value={name}>
                  {name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      </div>
      <div className="row-city-item-input">
        <div>
          <span>Estado:</span>
          <input
            name={"city-state"}
            className="avaliacao-narrativas-input"
            type="text"
            value={estado}
            onChange={(e) => setEstado(e.target.value)}
          />
        </div>
        <div className="multiple-select-wrapper">
          <span>Região:</span>
          <FormControl className={classes.formControl}>
            <Select
              labelId="demo-mutiple-name-label"
              id="demo-mutiple-name"
              multiple
              value={regiao}
              onChange={(e) => setRegiao(e.target.value)}
              input={<Input />}
              MenuProps={MenuProps}
            >
              {["Norte", "Nordeste", "Centro-Oeste", "Sudeste", "Sul"].map(
                (name) => (
                  <MenuItem key={name} value={name}>
                    {name}
                  </MenuItem>
                )
              )}
            </Select>
          </FormControl>
        </div>
      </div>
      <div className="row-city-item-input">
        <div>
          <span>Longitude:</span>
          <input
            name={"city-position-1"}
            className="avaliacao-narrativas-input"
            type="number"
            value={position[0]}
            onChange={(e) => setPosition([e.target.value, position[1]])}
          />
        </div>
        <div>
          <span>Latitude:</span>
          <input
            name={"city-position-2"}
            className="avaliacao-narrativas-input"
            type="number"
            value={position[1]}
            onChange={(e) => setPosition([position[0], e.target.value])}
          />
        </div>
      </div>
      <div className="row-city-item-input">
        <div>
          <span>Ano:</span>
          <input
            name={"city-name"}
            className="avaliacao-narrativas-input"
            type="text"
            type="number"
            min={1889}
            max={2019}
            value={periodo}
            onChange={(e) => setPeriodo(e.target.value)}
          />
        </div>
        <div className="multiple-select-wrapper">
          <span>Profissional:</span>
          <FormControl className={classes.formControl}>
            <Select
              labelId="demo-mutiple-name-label"
              id="demo-mutiple-name"
              multiple
              value={profissional}
              onChange={(e) => setProfissional(e.target.value)}
              input={<Input />}
              MenuProps={MenuProps}
            >
              {[
                "Arquiteto",
                "Engenheiro civil",
                "Engenheiro sanitarista",
                "Engenheiro agrônomo",
                "Agrimensor",
                "Topógrafo",
                "Urbanista",
                "Planejador",
                "Geógrafo",
                "Paisagista",
                "Técnico",
                "Não identificado",
                "Outro",
              ].map((name) => (
                <MenuItem key={name} value={name}>
                  {name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      </div>
      <div className="row-city-item-input">
        <div>
          <span>Cronologia do pensamento urbanístico:</span>
          <input
            name={"city-período"}
            className="cronologia-pensamento-input"
            type="text"
            value={link}
            onChange={(e) => setLink(e.target.value)}
          />
        </div>
      </div>
      <div className="row-city-item-input">
        <div>
          <span>Texto adicional:</span>
          <textarea
            name={"city-período"}
            className="cronologia-pensamento-input"
            value={text}
            onChange={(e) => setText(e.target.value)}
          />
        </div>
      </div>
      <img className="city-item-image" src={photo} />
      <div className="row-city-item-input">
        <div>
          <span>Foto:</span>
          <input
            name={"city-photo"}
            style={{ marginBottom: "15px" }}
            type="file"
            id={`edit-cities-file-input-${name}`}
            onChange={(e) => {
              if (e.target.files[0]) {
                setPhotoChanged(true);
                const blobUrl = URL.createObjectURL(e.target.files[0]); // blob is the Blob object
                setPhoto(blobUrl);
                setPhotoObject(e.target.files[0]);
              } else {
                setPhotoChanged(false);
                setPhoto("");
              }
            }}
            accept="image/*"
          />
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          marginBottom: "20px",
          paddingLeft: "15px",
        }}
      >
        <button
          onClick={() => update()}
          className="avaliacao-narrativas-button"
        >
          Cadastrar
        </button>
        {isLoading === true ? (
          <CircularProgress
            style={{ height: "30px", width: "30px", paddingLeft: "8px" }}
          />
        ) : null}
      </div>

      <div className="bottom-city-item-divider" />
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={severity}>
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
}
