import {
  ComposableMap,
  Geographies,
  Geography,
  Marker,
} from "react-simple-maps";
import "./styles.css";
const geoUrl =
  "https://raw.githubusercontent.com/deldersveld/topojson/master/world-countries.json";

export default function Oatlas({ list }) {
  return (
    <>
      <ComposableMap
        projection="geoAzimuthalEqualArea"
        projectionConfig={{
          rotate: [58, 20, 0],
          scale: 650,
        }}
      >
        <Geographies geography={geoUrl}>
          {({ geographies }) => {
            console.log(geographies);
            return geographies
              .filter((d) => d.id === "BRA")
              .map((geo) => {
                console.log(geo);
                return (
                  <Geography
                    key={geo.rsmKey}
                    geography={geo}
                    fill="#57397e"
                    stroke="#D6D6DA"
                  />
                );
              });
          }}
        </Geographies>
        {list.map(({ name, position, markerOffset, selected, showLabel }) => {
          if (showLabel === true) {
            return (
              <Marker key={name} coordinates={position}>
                <g
                  fill="none"
                  strokeWidth="2"
                  strokeLinecap="square"
                  strokeLinejoin="bevel"
                  transform="translate(-12, -24)"
                >
                  <text
                    x="-17"
                    y="40"
                    style={{ fontFamily: "quantico", fill: "#000" }}
                  >
                    {name}
                  </text>
                  <circle cx="18" cy="18" r="7" fill="#fa5805" />
                </g>
              </Marker>
            );
          } else {
            return null;
          }
        })}
      </ComposableMap>
    </>
  );
}
