import "./styles.css";
export default function CityDetails({ data }) {
  const showArray = (list) => {
    return list.map((item, i) => (
      <>
        {i === list.length - 1 ? (
          <p style={{ marginLeft: "5px" }}>{item}</p>
        ) : (
          <p style={{ marginLeft: "5px" }}> {item},</p>
        )}
      </>
    ));
  };

  return (
    <div className="cities-details-body">
      <h2 className="cities-details-title">
        {data.name}, {data.estado}
      </h2>
      <div className="cities-details-card-wrapper">
        <div className="cities-details-left-list-card-body">
          <img
            alt=""
            style={{ height: "100%", width: "auto" }}
            src={data.photo}
          />
        </div>
        <div className="cities-details-list-card-body">
          <div className="cities-details-data-wrapper">
            <p className="left-data-text">REGIÃO</p>
            <div className="right-data-text">{showArray(data.regiao)}</div>
          </div>
          <div className="cities-details-data-wrapper">
            <p className="left-data-text">ANO</p>
            <div className="right-data-text">
              {" "}
              <p>{data.periodo}</p>
            </div>
          </div>
          <div className="cities-details-data-wrapper">
            <p className="left-data-text">FUNÇÃO DOMINANTE</p>
            <p className="right-data-text"> {showArray(data.funcao)}</p>
          </div>
          <div className="cities-details-data-wrapper">
            <p className="left-data-text">EMPREENDEDOR</p>
            <div className="right-data-text">
              {" "}
              {showArray(data.Empreendendor)}
            </div>
          </div>
          <div className="cities-details-data-wrapper">
            <p className="left-data-text">PROFISSIONAL</p>
            <div className="right-data-text">
              {" "}
              {showArray(data.profissional)}
            </div>
          </div>
          <div className="cities-details-data-wrapper">
            <p className="left-data-text">PROJETO</p>
            <div className="right-data-text"> {showArray(data.Projeto)}</div>
          </div>
          <div className="cities-details-data-wrapper-bottom">
            <p>{data.text}</p>
            {data.link ? (
              <a href={data.link}>Cronologia do pensamento urbanístico</a>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}
