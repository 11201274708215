import "./styles.css";
import { useState } from "react";

export default function Narrativa({
  cities,
  setSelectedOption,
  selectedOption,
  setNarrativa,
  name,
  setName,
  email,
  setEmail,
  narrativa,
  handleNarrativa,
  _submit,
}) {
  const [selectedIndex, setSelectedIndex] = useState(1);

  const displayCities = cities.map((city, idx) => (
    <>
      <div
        key={city.name}
        onClick={() => selectCity(idx + 1)}
        className={
          selectedIndex === idx + 1
            ? "narrativa-city-element-selected"
            : "narrativa-city-element"
        }
      >
        <p>{idx + 1}</p>
      </div>
    </>
  ));
  const selectCity = (index) => {
    setSelectedIndex(index);
  };

  const showArray = (list) => {
    return list.map((item, i) => (
      <>
        {i === list.length - 1 ? (
          <p style={{ marginLeft: "5px" }}>{item}</p>
        ) : (
          <p style={{ marginLeft: "5px" }}> {item},</p>
        )}
      </>
    ));
  };

  const renderDetails = (index) => {
    return (
      <div className="narrativa-card-details-wrapper" key={index}>
        <div className="quiz-info-card-title">
          <h3 className="card-title">
           { cities[index - 1].name}
          </h3>
          <div className="card-title-divider" />
        </div>
        <div className="info-card-body">
          <div className="info-text-line">
            <h5 className="h4-title-card-info">Região: </h5>
            <h5 style={{ fontWeight: "normal" }}>
              {showArray(cities[index - 1].regiao)}
            </h5>
          </div>
          <div className="info-text-line">
            <h5 className="info-text-card">Período:</h5>
            <h5 style={{ fontWeight: "normal" }}>
              {cities[index - 1].periodo}
            </h5>
          </div>
          <div className="info-text-line">
            <h5 className="info-text-card">Função dominate: </h5>
            <h5 style={{ fontWeight: "normal" }}>
              {showArray(cities[index - 1].funcao)}
            </h5>
          </div>
          <div className="info-text-line">
            <h5 className="h4-title-card-info">Empreendedor: </h5>
            <h5 style={{ fontWeight: "normal" }}>
              {showArray(cities[index - 1].Empreendendor)}
            </h5>
          </div>
          <div className="info-text-line">
            <h5 className="h4-title-card-info">Profissional: </h5>
            <h5 style={{ fontWeight: "normal" }}>
              {showArray(cities[index - 1].profissional)}
            </h5>
          </div>
          <div className="info-text-line">
            <h5 className="h4-title-card-info">Projeto: </h5>
            <h5 style={{ fontWeight: "normal" }}>
              {showArray(cities[index - 1].Projeto)}
            </h5>
          </div>
          <div
            style={{ display: "flex", justifyContent: "center", width: "100%" }}
          >
            <img
              className="info-img-wrapper"
              alt=""
              src={cities[index - 1].photo}
            />
          </div>
          <div className="text-description-card">
            <p>{cities[index - 1].text}</p>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="narrativa-body-wrapper">
        <div className="narrativa-left-side">
          <div className="narrativa-cities-details-body">
            <div className="narrativa-cities-list-wrapper">{displayCities}</div>
            <div className="narrativa-city-details">
              {renderDetails(selectedIndex)}
            </div>
          </div>
        </div>
        <div className="narrativa-right-side">
          <div className="quiz-buttons-wrapper-right-side">
            <button
              onClick={() => {
                setSelectedOption("Descrição das cidades");
              }}
              className={
                selectedOption === "Descrição das cidades"
                  ? "quiz-button-selected"
                  : "quiz-button"
              }
            >
              Descrição das cidades
            </button>
            <button
              onClick={() => {
                setSelectedOption("Narrativa");
              }}
              className={"quiz-button-selected"}
            >
              Narrativa
            </button>
          </div>
          <div className="narrativa-input-wrapper">
            <p>Seu nome:</p>
            <input
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="narrativa-input"
            />
          </div>
          <br />
          <div className="narrativa-input-wrapper">
            <p>Seu e-mail:</p>
            <input
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="narrativa-input"
            />
          </div>
          <br />
          <div className="narrativa-input-wrapper">
            <p>Qual a sua narrativa?</p>
            <textarea
              value={narrativa}
              onChange={(e) => handleNarrativa(e.target.value)}
              className="narrativa-text-area"
            />
            <p
              className={
                narrativa.split(" ").length - 1 > 250 || narrativa === ""
                  ? "error-max-words"
                  : ""
              }
            >
              {narrativa === "" ? 0 : narrativa.split(" ").length}/250
            </p>
          </div>
          <div className="narrativa-button-wrapper">
            <button
              disabled={
                narrativa === "" || name === "" || narrativa === ""
                  ? true
                  : false
              }
              onClick={() => _submit()}
              className={
                narrativa === "" || name === "" || narrativa === ""
                  ? "narrativa-button-disabled"
                  : "narrativa-button"
              }
            >
              Enviar
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
