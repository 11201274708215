import { useEffect, useState } from 'react';
import Navbar from './../../components/navbar';
import Narrativa from './narrativa';
import firebase from './../../services/initFirebase';
import search from './../../assets/images/search.png';
import Button from './../../components/button';
import Helmet from 'react-helmet';
export default function Narrativas() {
    const [narrativas, setNarrativas] = useState([]);
    const [searchArgument, setSearchArgument] = useState("");
    const [all, setAll] = useState([]);
    const control = 0;
    useEffect(() => {
        getNarrativas();
    }, [control])
    const getNarrativas = async () => {
        await firebase.firestore().collection("narrativas").where("status", "==", "Aprovada").onSnapshot((snapshot) => {
            setNarrativas([]);
            snapshot.forEach((doc) => {
                setNarrativas(narrativas => narrativas.concat(doc.data()));
                setAll(all => all.concat(doc.data()));
            })
        })
    }

    const onSearch = (argument) => {
        var found = [];
        setSearchArgument(argument)
        if (argument === "") {
            setNarrativas(all);
            return;
        }
        for (let index = 0; index < all.length; index++) {
            var cities = all[index].cityNames;
            for (let i = 0; i < cities.length; i++) {
                var name = cities[i]
                if (name.toLowerCase().includes(argument.toLowerCase())) {
                    found.push(all[index]);
                } else {
                }
            }
        }
        setNarrativas(found)
    }
    return (
        <>
            <Navbar name="Mural de narrativas" />
            <div className="searchbar-wrapper">
                <div className="searchbar-body">
                    <input onChange={(e) => onSearch(e.target.value)} value={searchArgument} className="searchbar-element" />
                    <img alt="" className="searchbar-icon" src={search} />
                </div>
            </div>
            <div className="narrativas-list-body">
                {
                    narrativas.length < 1 ? null :
                        <>
                            {
                                narrativas.map((element, index) => (
                                    <Narrativa narrativa={element} key={index} />
                                ))
                            }</>
                }

            </div>
            <Helmet>
                <title>Narrativas</title>
                <meta name="description" content="Narrativas O atlas de novas cidades" />
            </Helmet>
            <Button />
        </>
    );
};