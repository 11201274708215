import "./styles.css";
export default function Researches({
  name,
  position,
  hideLabel,
  showLabel,
  index,
  selectCity,
  selectedCity,
  id,
}) {
  return (
    <>
      <div
        onClick={() => {
          hideLabel();
          selectCity();
        }}
        onMouseOver={() => {
          if (selectedCity !== id) {
            showLabel();
          }
        }}
        onMouseLeave={() => {
          if (selectedCity !== id) {
            hideLabel();
          }
        }}
        className={
          selectedCity === id
            ? "city-item-wrapper-selected"
            : "city-item-wrapper"
        }
      >
        <p>{name}</p>
      </div>
    </>
  );
}
