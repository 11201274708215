import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
export default function Filter({ filter, setFilter, forceUpdate }) {
  return (
    <>
      <FormControlLabel
        control={
          <Checkbox
            color="#ffffff"
            checked={filter.regiao.Norte}
            onChange={() => {
              var data = filter;
              data.regiao.Norte = !data.regiao.Norte;
              setFilter(data);
              forceUpdate();
            }}
            name="Norte"
          />
        }
        label={<p style={{ fontFamily: "quantico" }}>Norte</p>}
      />
      <FormControlLabel
        control={
          <Checkbox
            color="#ffffff"
            checked={filter.regiao.Nordeste}
            onChange={() => {
              var data = filter;
              data.regiao.Nordeste = !data.regiao.Nordeste;
              setFilter(data);
              forceUpdate();
            }}
            name="Nordeste"
          />
        }
        label={<p style={{ fontFamily: "quantico" }}>Nordeste</p>}
      />
      <FormControlLabel
        control={
          <Checkbox
            color="#ffffff"
            checked={filter.regiao["Centro-Oeste"]}
            onChange={() => {
              var data = filter;
              data.regiao["Centro-Oeste"] = !data.regiao["Centro-Oeste"];
              setFilter(data);
              forceUpdate();
            }}
            name="Centro-oeste"
          />
        }
        label={<p style={{ fontFamily: "quantico" }}>Centro-Oeste</p>}
      />
      <FormControlLabel
        control={
          <Checkbox
            color="#ffffff"
            checked={filter.regiao.Sudeste}
            onChange={() => {
              var data = filter;
              data.regiao.Sudeste = !data.regiao.Sudeste;
              setFilter(data);
              forceUpdate();
            }}
            name="Sudeste"
          />
        }
        label={<p style={{ fontFamily: "quantico" }}>Sudeste</p>}
      />
      <FormControlLabel
        control={
          <Checkbox
            color="#ffffff"
            checked={filter.regiao.Sul}
            onChange={() => {
              var data = filter;
              data.regiao.Sul = !data.regiao.Sul;
              setFilter(data);
              forceUpdate();
            }}
            name="Sul"
          />
        }
        label={<p style={{ fontFamily: "quantico" }}>Sul</p>}
      />
    </>
  );
}
