import { AiOutlineMail } from 'react-icons/ai';
import { BiMessageRoundedDetail } from 'react-icons/bi';
import close from './../../assets/images/close.png';
import lamps from './../../assets/images/lamps.png';
import location from './../../assets/images/location.png';
import { useState } from 'react';

export default function Button() {
    const [collapse, setCollapse] = useState(true);
    return (
        <>
            <script src="https://unpkg.com/react/umd/react.production.min.js"></script>
            <script src="https://unpkg.com/react-collapse/build/react-collapse.min.js"></script>
            <div style={{ zIndex: 1 }} className="bottom-buttons-wrapper">
                {
                    collapse ? null :
                        <div className="collapsed-buttons-wrapper">
                            <div className="button-bottom-wrapper-collapsed"><a href="https://www.instagram.com/cidadesnovas/?hl=pt"><img alt="" src={lamps} /></a></div>
                            <div className="button-bottom-wrapper-collapsed"><a href="mailto:atlascidadesnovas@gmail.com"><AiOutlineMail color="#ffffff" size={25} /></a></div>
                            <div className="button-bottom-wrapper-collapsed"><a href="http://www.fau.unb.br"><img alt="" src={location} /></a></div>
                        </div>
                }
                <div className="button-bottom-wrapper" onClick={() => setCollapse(!collapse)} >
                    {
                        collapse === false ? <img style={{ height: "40%", width: "auto" }} alt="" src={close} /> : <BiMessageRoundedDetail color="#ffffff" size={30} />
                    }
                </div>
            </div>
        </>
    )
}