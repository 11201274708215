import { NavLink } from "react-router-dom";

export default function Page() {
  return (
    <div className="center-admin-buttons">
      <NavLink
        style={{ marginTop: "7px" }}
        className="navButton"
        to="/admin/cidades"
      >
        Gerenciamento de cidades
      </NavLink>
      <NavLink
        style={{ marginTop: "7px" }}
        className="navButton"
        to="/admin/avaliacao"
      >
        Avaliação de narrativas
      </NavLink>
      <NavLink
        style={{ marginTop: "7px" }}
        className="navButton"
        to="/admin/sobreNos"
      >
        Gerenciamento + Sobre nós
      </NavLink>
      <NavLink
        style={{ marginTop: "7px" }}
        className="navButton"
        to="/admin/conteudo"
      >
        Conteúdo das páginas
      </NavLink>
    </div>
  );
}
