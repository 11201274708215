import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
export default function Filter({ filter, setFilter, forceUpdate }) {
  return (
    <div  style={{columnCount:4}}>
      <FormControlLabel
        control={
          <Checkbox
            color="#ffffff"
            checked={filter.periodo["1889 - 1899"]}
            onChange={() => {
              var data = filter;
              data.periodo["1889 - 1899"] = !data.periodo["1889 - 1899"];
              setFilter(data);
              forceUpdate();
            }}
            name="Norte"
          />
        }
        label={<p style={{ fontFamily: "quantico", whiteSpace:"nowrap" }}>1889 - 1899</p>}
      />
      <FormControlLabel
        control={
          <Checkbox
            color="#ffffff"
            checked={filter.periodo["1900 - 1909"]}
            onChange={() => {
              var data = filter;
              data.periodo["1900 - 1909"] = !data.periodo["1900 - 1909"];
              setFilter(data);
              forceUpdate();
            }}
            name="Nordeste"
          />
        }
        label={<p style={{ fontFamily: "quantico", whiteSpace:"nowrap" }}>1900 - 1909</p>}
      />
      <FormControlLabel
        control={
          <Checkbox
            color="#ffffff"
            checked={filter.periodo["1910 - 1919"]}
            onChange={() => {
              var data = filter;
              data.periodo["1910 - 1919"] = !data.periodo["1910 - 1919"];
              setFilter(data);
              forceUpdate();
            }}
            name="Centro-oeste"
          />
        }
        label={<p style={{ fontFamily: "quantico", whiteSpace:"nowrap" }}>1910 - 1919</p>}
      />
      <FormControlLabel
        control={
          <Checkbox
            color="#ffffff"
            checked={filter.periodo["1920 - 1929"]}
            onChange={() => {
              var data = filter;
              data.periodo["1920 - 1929"] = !data.periodo["1920 - 1929"];
              setFilter(data);
              forceUpdate();
            }}
            name="Centro-oeste"
          />
        }
        label={<p style={{ fontFamily: "quantico", whiteSpace:"nowrap" }}>1920 - 1929</p>}
      />
      <FormControlLabel
        control={
          <Checkbox
            color="#ffffff"
            checked={filter.periodo["1930 - 1939"]}
            onChange={() => {
              var data = filter;
              data.periodo["1930 - 1939"] = !data.periodo["1930 - 1939"];
              setFilter(data);
              forceUpdate();
            }}
            name="Sudeste"
          />
        }
        label={<p style={{ fontFamily: "quantico", whiteSpace:"nowrap" }}>1930 - 1939</p>}
      />
      <FormControlLabel
        control={
          <Checkbox
            color="#ffffff"
            checked={filter.periodo["1940 - 1949"]}
            onChange={() => {
              var data = filter;
              data.periodo["1940 - 1949"] = !data.periodo["1940 - 1949"];
              setFilter(data);
              forceUpdate();
            }}
            name="Sul"
          />
        }
        label={<p style={{ fontFamily: "quantico", whiteSpace:"nowrap" }}>1940 - 1949</p>}
      />
      <FormControlLabel
        control={
          <Checkbox
            color="#ffffff"
            checked={filter.periodo["1950 - 1959"]}
            onChange={() => {
              var data = filter;
              data.periodo["1950 - 1959"] = !data.periodo["1950 - 1959"];
              setFilter(data);
              forceUpdate();
            }}
            name="Norte"
          />
        }
        label={<p style={{ fontFamily: "quantico", whiteSpace:"nowrap" }}>1950 - 1959</p>}
      />
      <FormControlLabel
        control={
          <Checkbox
            color="#ffffff"
            checked={filter.periodo["1960 - 1969"]}
            onChange={() => {
              var data = filter;
              data.periodo["1960 - 1969"] = !data.periodo["1960 - 1969"];
              setFilter(data);
              forceUpdate();
            }}
            name="Nordeste"
          />
        }
        label={<p style={{ fontFamily: "quantico", whiteSpace:"nowrap" }}>1960 - 1969</p>}
      />
      <FormControlLabel
        control={
          <Checkbox
            color="#ffffff"
            checked={filter.periodo["1970 - 1979"]}
            onChange={() => {
              var data = filter;
              data.periodo["1970 - 1979"] = !data.periodo["1970 - 1979"];
              setFilter(data);
              forceUpdate();
            }}
            name="Centro-oeste"
          />
        }
        label={<p style={{ fontFamily: "quantico", whiteSpace:"nowrap" }}>1970 - 1979</p>}
      />
      <FormControlLabel
        control={
          <Checkbox
            color="#ffffff"
            checked={filter.periodo["1980 - 1989"]}
            onChange={() => {
              var data = filter;
              data.periodo["1980 - 1989"] = !data.periodo["1980 - 1989"];
              setFilter(data);
              forceUpdate();
            }}
            name="Sudeste"
          />
        }
        label={<p style={{ fontFamily: "quantico", whiteSpace:"nowrap" }}>1980 - 1989</p>}
      />
      <FormControlLabel
        control={
          <Checkbox
            color="#ffffff"
            checked={filter.periodo["1990 - 1999"]}
            onChange={() => {
              var data = filter;
              data.periodo["1990 - 1999"] = !data.periodo["1990 - 1999"];
              setFilter(data);
              forceUpdate();
            }}
            name="Sul"
          />
        }
        label={<p style={{ fontFamily: "quantico", whiteSpace:"nowrap" }}>1990 - 1999</p>}
      />
      <FormControlLabel
        control={
          <Checkbox
            color="#ffffff"
            checked={filter.periodo["2000 - 2009"]}
            onChange={() => {
              var data = filter;
              data.periodo["2000 - 2009"] = !data.periodo["2000 - 2009"];
              setFilter(data);
              forceUpdate();
            }}
            name="Norte"
          />
        }
        label={<p style={{ fontFamily: "quantico", whiteSpace:"nowrap" }}>2000 - 2009</p>}
      />
      <FormControlLabel
        control={
          <Checkbox
            color="#ffffff"
            checked={filter.periodo["2010 - 2019"]}
            onChange={() => {
              var data = filter;
              data.periodo["2010 - 2019"] = !data.periodo["2010 - 2019"];
              setFilter(data);
              forceUpdate();
            }}
            name="Nordeste"
          />
        }
        label={<p style={{ fontFamily: "quantico" , whiteSpace:"nowrap"}}>2010 - 2019</p>}
      />
    </div>
  );
}
